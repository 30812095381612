import React, { useState } from 'react';
import { Button, Drawer, Grid, IconButton, Link } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import { MobileCloseIcon, SwagUpLogoMobileIcon } from '../../shared/icons';
import { NavigateTo } from '../../shared/ui/common';
import { useMobileHeaderStyles } from './mobileMenu.styles';
import { fetchCollectionsDetails } from '../../apis';
import { updateNameForCategory } from '../../utils';

const CategoriesDrawer = ({ openCategoriesDrawer, setCategoriesDrawer, subCategoriesList, onClose }) => {
  const [openSubCategories, setOpenSubCategories] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [categoryTitle, setCategoryTitle] = useState('');
  const mobileHeaderClasses = useMobileHeaderStyles();
  const history = useHistory();
  const { data } = useQuery(['collectionsData'], () => fetchCollectionsDetails());
  const collectionsData = data?.productCollections?.collections ?? [];

  const LogoURL = process.env.REACT_APP_MAIN_DOMAIN;

  const closeDrawer = () => {
    setOpenSubCategories(false);
    setCategoryTitle('');
    setSubCategories([]);
  };

  return (
    <>
      <Drawer anchor="left" open={openCategoriesDrawer} onClose={() => setCategoriesDrawer(false)}>
        <Grid container style={{ padding: '20px 0px' }}>
          <Grid xs={12} container alignItems="center">
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                onClick={() => {
                  window.location.href = LogoURL;
                }}
              >
                <SwagUpLogoMobileIcon />
              </div>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '6px' }}>
              <IconButton onClick={() => setCategoriesDrawer(false)}>
                <MobileCloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item className={mobileHeaderClasses.content} sx={12}>
            <Button
              variant="text"
              component={Link}
              style={{ height: 0, padding: 0 }}
              onClick={() => {
                NavigateTo(history, '/catalog/');
                setCategoriesDrawer(false);
                setOpenSubCategories(false);
                onClose();
              }}
            >
              <Typography className={mobileHeaderClasses.menuText} style={{ fontSize: 28 }}>
                Categories
              </Typography>
            </Button>
            {subCategoriesList?.map((category, index) => {
              const formattedName = updateNameForCategory(category.name);
              const link = `/catalog/${formattedName}`;
              if (category.name !== '') {
                return (
                  <div
                    key={index}
                    className={mobileHeaderClasses.menuDiv}
                    onClick={() => {
                      setCategoryTitle(category.name);
                      setSubCategories(category?.subCategories);
                      setOpenSubCategories(true);
                    }}
                  >
                    <Button variant="text" component={Link} to={{ pathname: link }} style={{ padding: 0 }}>
                      <Typography className={mobileHeaderClasses.linkText}>{category.name}</Typography>
                    </Button>
                    <KeyboardArrowRight />
                  </div>
                );
              }
            })}
          </Grid>
        </Grid>
      </Drawer>
      <Drawer anchor="left" open={openSubCategories} onClose={closeDrawer}>
        <Grid container style={{ padding: '20px 0px' }}>
          <Grid xs={12} container alignItems="center">
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                onClick={() => {
                  window.location.href = LogoURL;
                }}
              >
                <SwagUpLogoMobileIcon />
              </div>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '6px' }}>
              <IconButton onClick={closeDrawer}>
                <MobileCloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item className={mobileHeaderClasses.content} sx={12}>
            <Button
              variant="text"
              component={Link}
              style={{ height: 0, padding: 0 }}
              onClick={() => {
                const link = updateNameForCategory(categoryTitle);
                const currentUrl = window.location.pathname;
                const index = currentUrl.indexOf('/dashboard/catalog');
                if (index !== -1) {
                  const newPath = currentUrl.slice(0, index);
                  NavigateTo(history, `${newPath}/catalog/${link}`);
                }
                setCategoriesDrawer(false);
                setOpenSubCategories(false);
                onClose();
              }}
            >
              <Typography className={mobileHeaderClasses.menuText} style={{ fontSize: 28 }}>
                {categoryTitle}
              </Typography>
            </Button>
            {subCategories?.map((category, index) => {
              const formattedName = category
                .toLowerCase()
                .replace(/&/g, '')
                .replace(/\s+/g, '-')
                .trim();
              const link = categoryTitle
                .toLowerCase()
                .replace(/&/g, '')
                .replace(/\s+/g, '-')
                .trim();
              const sublink = `/catalog/${link}/` + `${formattedName}`;
              return (
                <div
                  key={index}
                  className={mobileHeaderClasses.menuDiv}
                  onClick={() => {
                    NavigateTo(history, sublink);
                    setCategoriesDrawer(false);
                    setOpenSubCategories(false);
                    onClose();
                  }}
                >
                  <Button variant="text" component={Link} to={{ pathname: sublink }} style={{ padding: 0 }}>
                    <Typography className={mobileHeaderClasses.linkText}>{category}</Typography>
                  </Button>
                  <KeyboardArrowRight />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Drawer>
    </>
  );
};

export default CategoriesDrawer;
