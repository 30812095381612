import { shoppingExperienceColors } from '../../shared/constants';

const filterAndSortStyles = theme => ({
  filterButtonStyle: {
    backgroundColor: shoppingExperienceColors.textPrimary,
    color: shoppingExperienceColors.white,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.textPrimary
    }
  },
  filterButtonStyleMobile: {
    backgroundColor: shoppingExperienceColors.white,
    color: shoppingExperienceColors.textPrimary,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.white
    }
  },
  titleContainer: {
    padding: '16px 20px',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`,
    [theme.breakpoints.down('md')]: {
      padding: '16px 20px'
    }
  },
  title: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 12,
    [theme.breakpoints.down('md')]: {
      right: 20,
      top: 7
    },
    [theme.breakpoints.down('sm')]: {
      right: 0,
      top: 7
    }
  },
  filterContent: {
    padding: '0px 20px'
  }
});

export default filterAndSortStyles;
