import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { moneyStr } from '../../helpers/utils';
import styles from './styles/packOptions';
import gtm from '../../utils/gtm';

const useStyles = makeStyles(styles);

const TopContent = ({ pack, classes }) => (
  <>
    <Grid container alignItems="baseline">
      <Grid item xs>
        <p className={classes.packTitle}>{pack.name}</p>
      </Grid>
    </Grid>
    <Grid item xs container style={{ marginTop: 16, alignItems: 'center' }}>
      <span className={classes.packTitleSub}>{moneyStr(pack.price_per_pack)}</span>
      <span className={classes.pack}>/Pack*</span>
    </Grid>
  </>
);

const PackOptions = ({ selectedPack }) => {
  const classes = useStyles();
  const history = useHistory();
  const fromProducts = history.location.state?.fromProducts;

  const handlePackSelected = () => {
    gtm.onClickStartWithPresetPack(selectedPack.name);
    history.push({ pathname: '/preset-swag-packs/catalog', state: { slug: selectedPack.slug, fromProducts } });
  };

  return (
    <div className={classes.packOptionsContent}>
      <TopContent pack={selectedPack} classes={classes} />
      <p className={classes.includesDescription} style={{ padding: '16px 0px', whiteSpace: 'pre-wrap' }}>
        {selectedPack.description}
      </p>
      <p className={classes.includesTitle}>Pack includes:</p>
      <div className={classes.includesDescription}>
        {selectedPack.items && selectedPack.items.map(({ item }) => <p key={item.id}>• {item.name}</p>)}
      </div>
      <Grid
        className={classes.startButton}
        container
        item
        md={12}
        xs="auto"
        style={{ textAlign: 'center', paddingTop: 44, justifyContent: 'left' }}
      >
        <Button variant="primary" size="large" onClick={handlePackSelected}>
          Start with this Pack
        </Button>
      </Grid>
      <p className={classes.packNote}>*Price based on 100 units and 1 color design.</p>
    </div>
  );
};

export default PackOptions;
