import React, { useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Grid, makeStyles, withStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { isEmpty, isNull, isUndefined } from 'lodash';
import styles from './styles/sendSwagConfiguration';
import { StylessButton } from '../../buttons';
import { toSentenceCase } from '../../account/MembershipCommon';

const useStyles = makeStyles(styles);

const SmallCheckbox = withStyles({
  root: {
    padding: 0,
    height: 14,
    width: 14,
    color: '#989EA4',
    '&$checked': { color: '#3577d4' },
    '& .MuiSvgIcon-root': {
      height: 14,
      width: 14
    }
  },
  checked: {}
})(Checkbox);

// const employeeTypes = [
//   { id: 1, label: 'Employee Full-Time - US', value: 'Employee Full-Time - US' },
//   { id: 2, label: 'Employee Part-Time - US', value: 'Employee Part-Time - US' },
//   { id: 3, label: 'Employee Full-Time - CAD', value: 'Employee Full-Time - CAD' },
//   { id: 4, label: 'Employee Part-Time - CAD', value: 'Employee Part-Time - CAD' },
//   { id: 5, label: 'Employee Full-Time - International', value: 'Employee Full-Time - International' },
//   { id: 6, label: 'International Worker', value: 'International Worker' }
// ];

const SendSwagConfigurationEmployee = ({ action, onActionUpdate, employeeTypes }) => {
  const { settings } = action;

  useEffect(() => {
    if (isUndefined(settings?.employeeTypes) || isNull(settings?.employeeTypes)) {
      onActionUpdate({
        ...action,
        settings: {
          ...settings,
          employeeTypes: employeeTypes.map(et => et)
        }
      });
    }
  }, [settings]);

  const classes = useStyles();

  return (
    <Box>
      <Typography variant="h5SemiBoldInter" className={classes.actionTitle} style={{ marginBottom: 24 }}>
        Automation Settings
      </Typography>
      <Typography variant="h6SemiBoldInter" className={classes.actionSubTitle} style={{ marginBottom: 42 }}>
        What employee type(s) do you want to send this to?
      </Typography>
      <Box className={classes.integrationProductsWrapper}>
        <Box className={classes.selectionActionBox}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={11}>
              <Grid container>
                <Grid item>
                  <StylessButton
                    onClick={() =>
                      onActionUpdate({
                        ...action,
                        settings: {
                          ...action.settings,
                          employeeTypes: employeeTypes.map(et => et)
                        }
                      })
                    }
                  >
                    <Typography variant="body4RegularInter" style={{ color: '#3577d4' }}>
                      Select all
                    </Typography>
                  </StylessButton>
                </Grid>
                <Grid item xs />
                <Grid item>
                  <StylessButton
                    onClick={() =>
                      onActionUpdate({
                        ...action,
                        settings: {
                          ...action.settings,
                          employeeTypes: []
                        }
                      })
                    }
                  >
                    <Typography variant="body4RegularInter" style={{ color: '#C62828' }}>
                      Clear all
                    </Typography>
                  </StylessButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={11}>
            <Box className={classes.employeeTypesPanel}>
              <Grid container>
                {employeeTypes.map(et => (
                  <Grid key={et.id} item xs={4}>
                    <FormControlLabel
                      style={{ marginLeft: 0, marginBottom: 16 }}
                      label={
                        <Typography variant="body4RegularInter" style={{ marginLeft: 6 }}>
                          {toSentenceCase(et)}
                        </Typography>
                      }
                      control={
                        <SmallCheckbox
                          checked={!!action.settings?.employeeTypes?.find(t => et === t)}
                          onChange={({ target: { checked } }) =>
                            checked
                              ? onActionUpdate({
                                  ...action,
                                  settings: {
                                    ...action.settings,
                                    employeeTypes: [...action.settings.employeeTypes, et]
                                  }
                                })
                              : onActionUpdate({
                                  ...action,
                                  settings: {
                                    ...action.settings,
                                    employeeTypes: action.settings.employeeTypes.filter(
                                      t => t?.toLowerCase() !== et?.toLowerCase()
                                    )
                                  }
                                })
                          }
                        />
                      }
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SendSwagConfigurationEmployee;
