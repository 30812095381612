import { getPriceForQuantity } from './getPriceForQuantity';

export const prepareDataForCartPopup = productData => {
  const { categories = [], media = [], pricingOption = [], quantity = 1, color, decorationOptions = [] } = productData;

  const thumbnailURL =
    media?.find(mediaItem => mediaItem.mediaCategory === 'product' && mediaItem.color === color.colorName)?.url || '';
  const category = categories?.length ? categories[0]?.name : '';
  const price = getPriceForQuantity(pricingOption[0].pricingMatrix, quantity);

  return {
    ...productData,
    thumbnailURL,
    category,
    price,
    quantity,
    color: color?.hex,
    colorName: color?.colorName,
    decorationMethod: decorationOptions.length ? decorationOptions[0] : ''
  };
};
