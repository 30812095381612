import React, { useEffect, useState } from 'react';
import { makeStyles, Grid, Button, Box, Avatar } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { useQueries, useQueryClient, useIsFetching } from 'react-query';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ArrowForward, Mail, Phone } from '@material-ui/icons';
import { setShowDashboardMessage } from '../../actions';
import { HeaderSection, Header } from './SharedComponents';
import { CenteredGrid, Alert } from '../shared';
import Loader from '../global/Loader';
import EmptyState from './EmptyState';
import Orders from './Orders';
import Shipments from './Shipments';
import Proofs from './Proofs';
import styles from './styles/DashboardSummary';
import apiPaths from '../../helpers/apiPaths';
import { useCompany, useProfile } from '../../hooks';
import { accountOrdersApi, proofsApi } from '../../apis/swagup';
import MembershipPanel from './MembershipPanel';
import { joinFields, moneyStr } from '../../helpers/utils';
import EarlyBirdBanner from './EarlyBirdBanner';
// import StorefrontsBanner from './StorefrontsBanner';
import IntegrationsBanner from './IntegrationsBanner';
import { StylessButton } from '../buttons';
import { CustomTooltip } from '../products/commonProductsElements';
import JulyChristmasBanner from './JulyChristmasBanner';
import MembershipsBanner from './MembershipsBanner';
import NewShoppingExpBanner from '../../shoppingExperience/components/Banner/NewShoppingExpBanner';

const useStyles = makeStyles(styles);

const eoQueryKey = [apiPaths.employeeOrders, { limit: 4 }];

const OrdersSection = ({ orders, classes, isEnterpriseFlowActive }) => {
  return (
    <Grid container className={classes.orderContainer} direction="column">
      <Grid item style={{ width: '100%' }}>
        <HeaderSection classes={classes} sectionName="orders" hideViewAll={orders.length === 0} />
      </Grid>
      <Grid container className={clsx(classes.orderGridList, { [classes.orderListES]: orders.length === 0 })}>
        <Grid item xs={12}>
          {orders.length === 0 ? (
            <EmptyState
              sectionName="orders"
              title="No Recent Orders"
              description="You’ve placed no Swag orders recently."
            />
          ) : (
            orders.map((order, index) => (
              <Orders
                key={order.id}
                order={order}
                showLine={index !== 0}
                isEnterpriseFlowActive={isEnterpriseFlowActive}
              />
            ))
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

const ProofsSection = ({ proofs, classes }) => (
  <Grid container className={classes.proofContainer}>
    <HeaderSection classes={classes} sectionName="proofs" hideViewAll={proofs.length === 0} />
    <Grid item xs={12} className={clsx(classes.proofItem, { [classes.proofItemES]: proofs.length === 0 })}>
      {proofs.length === 0 ? (
        <EmptyState sectionName="proofs" title="No Proofs" description="You’ve submitted no order requests yet." />
      ) : (
        <Proofs proofs={proofs} />
      )}
    </Grid>
  </Grid>
);

const YourSwagUpRepresentative = ({ representative, classes }) => {
  const [copyNotification, setCopyNotification] = useState('Click to copy');

  const copyToClipboard = e => {
    navigator.clipboard.writeText(representative.email);
    setCopyNotification('Email address copied to clipboard');

    setTimeout(() => {
      setCopyNotification('Click to copy');
    }, 3000);

    e.stopPropagation();
  };

  if (!representative) return null;
  return (
    <Grid container className={classes.proofContainer} style={{ height: 168 }}>
      <HeaderSection classes={classes} sectionName="representative" hideViewAll />
      <Grid item xs={12} className={classes.proofItem}>
        <Grid container alignItems="center" spacing={4}>
          <Grid item xs>
            <Grid container alignItems="center">
              <Grid item>
                <Avatar src={representative.photo} className={classes.repAvatar} />
              </Grid>
              <Grid item xs style={{ paddingLeft: 16 }}>
                <Typography variant="subtitle3SemiBold" className={classes.repName}>
                  {joinFields([representative.first_name, representative.last_name], ' ')}
                </Typography>
                <CustomTooltip title={copyNotification}>
                  <StylessButton onClick={copyToClipboard}>
                    <Typography variant="body4RegularInter" className={classes.repName}>
                      {representative.email}
                    </Typography>
                  </StylessButton>
                </CustomTooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <a href={`tel:${representative.phone_number || representative.mobile}`}>
              <Phone className={classes.contactIcon} />
            </a>
          </Grid>
          <Grid item>
            <CustomTooltip title={copyNotification}>
              <StylessButton onClick={copyToClipboard}>
                <Mail className={classes.contactIcon} />
              </StylessButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
const ShipmentsSection = ({ classes }) => {
  const queryClient = useQueryClient();
  const shipments = queryClient.getQueryData(eoQueryKey)?.results || [];

  return (
    <Grid container className={classes.shipmentsContainer}>
      <Grid item xs={12}>
        <HeaderSection classes={classes} sectionName="shipments" hideViewAll={shipments.length === 0} />
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          className={clsx(classes.shipmentGridList, { [classes.shipmentListES]: shipments.length === 0 })}
        >
          <Grid item xs={12}>
            {shipments.length === 0 ? (
              <EmptyState
                sectionName="shipments"
                title="No Recent Shipments"
                description="You have not sent any Swag recently."
              />
            ) : (
              shipments.map((shipment, index) => (
                <Shipments key={shipment.id} shipment={shipment} showLine={index !== 3} />
              ))
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ViewCredits = ({ classes }) => (
  <Link to="/billing/add-credits">
    <Box className={classes.yourCreditsWrapper}>
      <Typography variant="body4RegularInter" className={classes.yourCreditsText}>
        Add Funds
      </Typography>
      <ArrowForward viewBox="0 -3 30 32" style={{ marginLeft: 4 }} />
    </Box>
  </Link>
);

const AvailableCreditsPanel = ({ account, classes }) => {
  const { leftBarNavigation } = useFlags();
  return (
    <Grid item container lg={4} md={4} sm={12} xs={12}>
      <Grid
        container
        className={leftBarNavigation ? classes.availableCreditsPanelWithLeftNavBar : classes.availableCreditsPanel}
      >
        <Grid item>
          <img className={classes.membershipImage} src="/images/dashboard/payment-card.png" alt="payment card.png" />
        </Grid>
        <Grid item xs className={classes.rewardsDetails}>
          <Grid className={classes.cardBalance}>
            <Typography variant="body2MediumInter" className={classes.statusPanelTitle}>
              Swag Card Balance
            </Typography>
            <ViewCredits classes={classes} />
          </Grid>

          <Grid container alignItems="center">
            <Grid item xs>
              <Grid container spacing={4} alignItems="flex-end">
                <Grid item>
                  <Typography variant="h5BoldInter" className={classes.availableCredits}>
                    {moneyStr(account.balance)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {/* <Grid item>
            <ViewCredits classes={classes} />
          </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const ReleasesSection = ({ classes }) => {
  const handleOpenNews = () => {
    try {
      // eslint-disable-next-line no-undef
      if (Intercom) Intercom('showSpace', 'news');
      return 0;
    } catch {
      return -1;
    }
  };

  return (
    <Grid container className={classes.releasesContainer}>
      <Grid item md={12}>
        <img src="/images/dashboard/announcement.png" alt="announcement icon" className={classes.releasesImg} />
      </Grid>
      <Grid item md={12} className={classes.releasesContentContainer}>
        <Typography variant="h4BoldInter" style={{ marginBottom: 8 }}>
          What&apos;s New
        </Typography>
        <Typography variant="body2RegularInter" style={{ marginBottom: 16 }}>
          View our latest features and updates on improving your experience.
        </Typography>
        <Button variant="contained" color="secondary" onClick={handleOpenNews} style={{ height: 48 }}>
          See Updates
        </Button>
      </Grid>
    </Grid>
  );
};

const commonFilters = { limit: 4, createdAt: 'last_30_days' };

const DashboardSummary = () => {
  // const showMessage = useSelector(state => state.showDashboardMessage);

  const queries = useQueries([
    {
      queryKey: [apiPaths.accountOrders, commonFilters],
      queryFn: () => {
        const query = new URLSearchParams();
        query.set('limit', commonFilters.limit);
        query.set('createdAt', commonFilters.createdAt);
        query.set('ordering', '-created_at');
        return accountOrdersApi.fetchOrders(query.toString());
      }
    },
    {
      queryKey: apiPaths.proofs,
      queryFn: () => {
        const query = new URLSearchParams();
        query.set('ordering', '-created_at');
        return proofsApi.fetch(query.toString());
      }
    }
  ]);

  const { data: company } = useCompany();
  const isEnterpriseAccount = !!company?.enterprise;
  // const { data: profile } = useProfile();
  // const { first_name: user } = profile || {};

  const isFetchingShipments = useIsFetching(eoQueryKey);
  const dispatch = useDispatch();
  const {
    swagupMembershipPlans,
    earlyBirdPromotion,
    storefronts,
    leftBarNavigation,
    julyChristmas,
    membershipsBanner,
    newShoppingExperience,
    enterpriseGlobalCheckoutFlowTemp031722: enterpriseCheckoutActive
  } = useFlags();

  const isEnterpriseFlowActive = isEnterpriseAccount && enterpriseCheckoutActive;
  const classes = useStyles({ earlyBirdPromotion });

  useEffect(() => {
    return () => dispatch(setShowDashboardMessage(false));
  }, [dispatch]);

  const isLoading = isFetchingShipments || queries.some(q => q.status === 'loading');

  const mainBanner = () => {
    if (membershipsBanner) return <MembershipsBanner />;
    if (julyChristmas) return <JulyChristmasBanner />;
    if (storefronts && !newShoppingExperience) return <IntegrationsBanner />;
    if (earlyBirdPromotion) return <EarlyBirdBanner />;
    if (newShoppingExperience) return <NewShoppingExpBanner />;

    return null;
  };

  if (isLoading) return <Loader />;

  const orders = queries[0]?.data?.results ?? [];
  const proofs = queries[1]?.data?.results ?? [];

  return (
    <div className={classes.main}>
      <Grid container justifyContent="center">
        <CenteredGrid item className={classes.center}>
          <Grid container alignItems="baseline">
            {mainBanner()}
            {/* {showMessage && !earlyBirdPromotion && (
              <Alert style={{ marginBottom: 24 }}>
                <span className={classes.alertText}>Welcome Back, {user} 👋.&nbsp; </span>
              </Alert>
            )} */}
            {!leftBarNavigation && <Header classes={classes} />}
            {swagupMembershipPlans && (
              <Grid container alignItems="flex-end">
                <MembershipPanel />
                <AvailableCreditsPanel account={company} classes={classes} />
              </Grid>
            )}

            <Grid item container lg={8} md={8} sm={12} xs={12} className={classes.separateOrders}>
              <OrdersSection orders={orders} classes={classes} isEnterpriseFlowActive={isEnterpriseFlowActive} />
              <ShipmentsSection classes={classes} />
            </Grid>
            <Grid item container lg={4} md={4} sm={12} xs={12}>
              <YourSwagUpRepresentative representative={company?.account_manager} classes={classes} />
              <ProofsSection proofs={proofs} classes={classes} />
              <ReleasesSection classes={classes} />
            </Grid>
          </Grid>
        </CenteredGrid>
      </Grid>
    </div>
  );
};

export default DashboardSummary;
