import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Switch, Route, Redirect, useRouteMatch, useHistory } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Helmet } from '../../shared';
import Breadcrumbs from '../../shared/Breadcrumbs';
import Catalog from './Catalog';
import CatalogNew from './CatalogNew';
import Cart from './Cart';
import CheckoutForm from './CheckoutForm';
import ProductDetailsModal from './ProductDetailsModal';
import { initializeCatalog } from '../../../actions/catalog';
import { clearCart } from '../../../actions/cart';
import { usePresetPacks, useQueryParams } from '../../../hooks';
import tags from '../../../apis/seoTags';
import styles from './CatalogHome.styles';
import useDiscountPricing from '../../../hooks/useDiscountPricing';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import CatalogBanner from '../../shared/CatalogBanner';
import JulyChristmasBanner from '../../dashboard/JulyChristmasBanner';
import MembershipsBanner from '../../dashboard/MembershipsBanner';

const useStyles = makeStyles(styles);

const CatalogHome = ({ isBulk }) => {
  const [productToOpen, setProductToOpen] = React.useState(null);

  const classes = useStyles();

  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { view } = useRouteMatch(`${path}/:view?`).params;

  const [slug] = React.useState(history.location.state?.slug);
  const fromProducts = history.location.state?.fromProducts;

  const { getCatalogWithDiscount } = useDiscountPricing();
  const { data: template } = usePresetPacks({ slug, enabled: Boolean(slug) });

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(initializeCatalog(isBulk, template, getCatalogWithDiscount));

    return () => dispatch(clearCart());
  }, [dispatch, getCatalogWithDiscount, isBulk, template]);

  const { items: catalog } = useSelector(state => state.catalog);
  const cartEmpty = useSelector(state => state.cart.items.length === 0);

  const query = useQueryParams();
  const removeOpenQueryParam = React.useCallback(() => {
    query.delete('details');
    history.replace({ ...history.location, search: query.toString() });
  }, [query, history]);

  const productIdToOpen = +query.get('details') || -1;

  React.useEffect(() => {
    if (isEmpty(catalog)) return;

    if (productIdToOpen === -1) removeOpenQueryParam();
    else {
      const product = catalog
        .map(c => c.items)
        .flat()
        .find(p => p.id === productIdToOpen);

      if (!product) {
        removeOpenQueryParam();
      } else {
        setProductToOpen(product);
      }
    }
  }, [catalog, productIdToOpen, removeOpenQueryParam]);

  const nextStep = !view && `${path}/checkout`;

  const [tag, breadcrumbTitle, onSuccessPathStart] = isBulk
    ? [tags.bulkCatalog, 'Build Custom Swag', 'bulk']
    : [tags.customPacksCatalog, 'Build Swag Pack', 'custom-swag-packs'];

  const title = 'Complete Mockups Request';

  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck.isPlatinum;
  const { catalogMembershipBanner, catalogCollections, julyChristmas, membershipsBanner } = useFlags();

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const utm_ad_group = urlParams.get('utm_ad_group') || null;
    const utm_landing_page = urlParams.get('utm_landing_page') || null;

    if (utm_ad_group && utm_landing_page)
      localStorage.setItem('custom_utm_parameter', JSON.stringify({ utm_ad_group, utm_landing_page }));
    else {
      localStorage.removeItem('custom_utm_parameter');
    }
  }, []);

  const isCheckoutPage = React.useMemo(() => {
    const checkoutPages = ['/dashboard/preset-swag-packs/catalog/checkout', '/dashboard/bulk/catalog/checkout'];
    return checkoutPages.includes(location.pathname);
  }, [location.pathname]);

  const mainBanner = () => {
    if (membershipsBanner) return <MembershipsBanner catalog />;
    if (julyChristmas) return <JulyChristmasBanner catalog />;
    if (catalogMembershipBanner && !PlatinumMembershipTier)
      return (
        <CatalogBanner
          image="/images/pricing/platinum2x.png"
          title="Platinum Members Save up to 25% per Order"
          buttonLabel="Upgrade Now"
          buttonLink="/membership/manage-membership-switch"
        />
      );

    return null;
  };

  return (
    <main className={classes.root} style={{ ...(isCheckoutPage ? { marginLeft: 60, marginRight: 300 } : {}) }}>
      <Switch>
        <Route path={path} exact>
          <Helmet tags={tag} />
          {mainBanner()}
          {catalogCollections ? (
            <CatalogNew nextStep={nextStep} isBulk={isBulk} />
          ) : (
            <Catalog nextStep={nextStep} isBulk={isBulk} />
          )}
        </Route>
        {cartEmpty && <Redirect to={{ pathname: path, state: { fromProducts } }} />}
        <Route path={`${path}/checkout`}>
          <Helmet tags={tag} />
          <Breadcrumbs
            links={[{ title: breadcrumbTitle, to: { pathname: url, state: { fromProducts } } }, { title }]}
          />
          <CheckoutForm fromProducts={fromProducts} onSuccessPathStart={onSuccessPathStart} />
        </Route>
      </Switch>
      <Cart nextStep={nextStep} fromProducts={fromProducts} />
      {productToOpen?.id === productIdToOpen && (
        <ProductDetailsModal product={productToOpen} onClose={removeOpenQueryParam} />
      )}
    </main>
  );
};

export default CatalogHome;
