import { shoppingExperienceColors } from '../../shared/constants';

const CheckOutPageStyles = theme => ({
  buttonStyle: {
    marginTop: 20,
    maxWidth: 150,
    minWidth: 150,
    height: 56,
    color: 'white',
    borderRadius: 4,
    backgroundColor: shoppingExperienceColors.textPrimary,
    '&:hover': {
      backgroundColor: shoppingExperienceColors.textPrimary
    }
  },
  textButtonStyle: {
    maxWidth: 150,
    minWidth: 150,
    height: 56,
    color: shoppingExperienceColors.primary
  },
  addProjectDetails: {
    width: '100%',
    textAlign: 'center',
    paddingTop: '20px'
  },
  contactEmail: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px'
  },
  accordionRoot: {
    width: '100%',
    boxShadow: 'none',
    borderRadius: '0px',
    '&.Mui-disabled': {
      backgroundColor: shoppingExperienceColors.white,
      borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`
    }
  },
  accordionSummary: {
    backgroundColor: shoppingExperienceColors.lightGray,
    cursor: 'default',
    padding: '0px'
  },
  accordionSummaryDisabled: {
    backgroundColor: 'transparent'
  },
  noBorderRadiusTop: {
    '&.MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px'
    }
  },
  noBorderRadiusBottom: {
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: '0px',
      borderBottomRightRadius: '0px'
    }
  },
  textBox: {
    width: '100%',
    height: '115px',
    '& .MuiOutlinedInput-root': {
      borderRadius: '5px',
      '& fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&:hover fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      },
      '&.Mui-focused fieldset': {
        borderColor: shoppingExperienceColors.borderLineGray
      }
    },
    '& .MuiInputBase-input': {
      height: '115px',
      overflowY: 'auto',
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '16px'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderRadius: '5px'
    },
    '& .MuiInputBase-inputMultiline': {
      minHeight: '115px',
      maxHeight: '115px',
      overflowY: 'auto'
    },
    '& .MuiOutlinedInput-multiline': {
      padding: '12px 16px'
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      color: shoppingExperienceColors.info,
      opacity: 1
    }
  },
  characterCount: {
    color: shoppingExperienceColors.info,
    zIndex: 999,
    position: 'absolute',
    bottom: '-20px',
    right: '20px'
  },
  totalEstimate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  subTotalEstimate: {
    marginBottom: '14px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  noCCInfo: {
    margin: '20px 0px',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  cartContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  cartItems: {
    marginBottom: '20px',
    marginRight: '15px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  scrollBar: {
    '& .ScrollbarsCustom-Track.ScrollbarsCustom-TrackY': {
      width: '4px !important',
      height: 'calc(100% - 10px) !important',
      top: '0px !important',
      right: '5px !important',
      background: `${shoppingExperienceColors.bannarBGColorMobile} !important`
    },
    '& .ScrollbarsCustom-Thumb': {
      background: `${shoppingExperienceColors.black} !important`
    }
  },
  accordion: {
    width: '100%',
    boxShadow: 'none',
    '& .MuiAccordion-root': {
      background: 'none'
    },
    '& .MuiAccordionDetails-root': {
      padding: '0px'
    },
    '&::before': {
      backgroundColor: 'transparent'
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px !important'
    }
  },
  accordionProjectDetails: {
    '&.MuiAccordionDetails-root': {
      padding: '0px !important'
    }
  },
  accordionSummaryCart: {
    height: 24,
    minHeight: 24,
    '&.Mui-expanded': {
      minHeight: 54
    },
    '& .MuiAccordionSummary-content': {
      margin: '0px'
    },
    '& .MuiAccordionSummary-root': {
      padding: '0px !important'
    }
  },
  itemContainer: {
    gap: 20,
    height: 100,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'fit-content'
    },
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px'
  },
  image: {
    width: 60,
    height: 60,
    objectFit: 'contain'
  },
  confetti: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    zIndex: 999,
    pointerEvents: 'none'
  },

  // * upload Vector Banner Start
  bannerContainer: {
    backgroundColor: '#F5F8FF',
    borderRadius: '5px',
    padding: '16px 20px',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5)
    }
  },
  iconContainer: {
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  contentContainer: {
    flexGrow: 1
  },
  modalContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  modalContent: {
    backgroundColor: shoppingExperienceColors.white,
    padding: '20px',
    borderRadius: '5px',
    boxShadow: 'none !important',
    width: '100%',
    maxWidth: '800px',
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100%'
    }
  },
  modalImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  vectorImage: {
    width: '100%',
    height: 'auto',
    borderRadius: '20px',
    objectFit: 'cover'
  },
  closeIcon: {
    cursor: 'pointer',
    zIndex: 1000,
    color: shoppingExperienceColors.black
  },
  // * upload Vector Banner End

  typographyWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: '16px'
  },
  datePickerContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '20px'
  },
  gridContainer: {
    marginBottom: '20px'
  },
  noPaddingInput: {
    paddingLeft: '0px !important'
  },
  infoRow: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px'
  },
  toggleRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  couponContainer: {
    width: '100%',
    maxWidth: '100%'
  },
  infoIcon: {
    marginRight: '4px',
    fontSize: '14px'
  },
  mobileTotalEstimate: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '40px'
  },
  tooltip: {
    backgroundColor: shoppingExperienceColors.white,
    color: shoppingExperienceColors.textPrimary,
    fontFamily: 'Inter',
    fontSize: '14px',
    borderRadius: '5px',
    textAlign: 'center'
  },
  dialog: {
    width: 500,
    maxWidth: 500,
    borderRadius: 5,
    paddingBottom: 24,
    [theme.breakpoints.down('md')]: {
      width: 800
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      left: 0,
      position: 'absolute',
      margin: 0,
      bottom: 0,
      minHeight: '100vh'
    }
  }
});

export default CheckOutPageStyles;
