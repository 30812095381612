import React from 'react';
import { Typography as SwagupTypography } from '@swagup-com/components';

const BannerSubtitleText = () => {
  return (
    <SwagupTypography variant="body3RegularInter">
      Become a <strong>SwagUp Platinum Member</strong> today to take advantage of this offer + even more benefits!
    </SwagupTypography>
  );
};

export default BannerSubtitleText;
