import React, { useState } from 'react';
import { Button, Drawer, Grid, IconButton, Link } from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useQuery } from 'react-query';
import MembershipHand from '../../assets/images/products/membership_hand.png';
import { MobileCloseIcon, SwagUpLogoMobileIcon } from '../../shared/icons';
import { NavigateTo } from '../../shared/ui/common';
import Banner from '../Banner/banner';
import { useMobileHeaderStyles } from './mobileMenu.styles';
import { fetchCollectionsDetails } from '../../apis';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import BannerSubtitleText from '../Banner/bannerSubtitleText';
import CategoriesDrawer from './categoriesDrawer';

const MobileMenu = ({ open, onClose, menus, onOpen, logout, subCategoriesList }) => {
  const [openCollectionsDrawer, setOpenCollectionsDrawer] = useState(false);
  const [openCategoriesDrawer, setCategoriesDrawer] = useState(false);
  const mobileHeaderClasses = useMobileHeaderStyles();
  const history = useHistory();
  const { data } = useQuery(['collectionsData'], () => fetchCollectionsDetails());
  const collectionsData = data?.productCollections?.collections ?? [];

  const handleNavigateWithClose = (link, closeDrawer) => {
    closeDrawer();
    setTimeout(() => {
      NavigateTo(history, link);
    }, 300);
  };

  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck?.isPlatinum;
  const LogoURL = process.env.REACT_APP_MAIN_DOMAIN;

  return (
    <>
      {/* Main Menu Drawer */}
      <Drawer anchor="left" open={open} onClose={onClose}>
        <Grid container style={{ padding: '20px 0px' }}>
          <Grid xs={12} container alignItems="center">
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <div
                onClick={() => {
                  window.location.href = LogoURL;
                }}
              >
                <SwagUpLogoMobileIcon />
              </div>
            </Grid>
          </Grid>
          <div
            style={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '9px 18px',
              display: 'flex',
              width: '100%',
              height: '20px'
            }}
          >
            <IconButton onClick={onClose}>
              <MobileCloseIcon />
            </IconButton>
          </div>

          <Grid item className={mobileHeaderClasses.content} sx={12}>
            {menus.map((menu, index) => (
              <div
                key={index}
                className={mobileHeaderClasses.menuDiv}
                onClick={() => {
                  if (menu.isExternal) {
                    window.open(menu.link, '_blank');
                  } else if (menu.label === 'Categories') {
                    setCategoriesDrawer(true);
                  } else if (menu.label === 'Collections') {
                    setOpenCollectionsDrawer(true);
                  } else if (menu.label === 'Profile') {
                    handleNavigateWithClose(menu.link, onClose);
                  } else if (menu.label === 'Sign In') {
                    onClose();
                    onOpen();
                  } else if (menu.label === 'Log Out') {
                    logout();
                  } else {
                    handleNavigateWithClose(menu.link, onClose);
                  }
                }}
              >
                <Button variant="text" component={Link} to={{ pathname: menu.link }}>
                  <Typography className={mobileHeaderClasses.menuText}>{menu.label}</Typography>
                </Button>
                <KeyboardArrowRight />
              </div>
            ))}
          </Grid>
          {!PlatinumMembershipTier && (
            <Banner
              image={`${MembershipHand}`}
              title="Platinum Members Get 5% Off All Products"
              mobileTitle="Platinum Members Get 5% Off All Products"
              subtitle={<BannerSubtitleText />}
              buttonLabel="Upgrade to Platinum"
              buttonLink="https://www.swagup.com/membership"
              highlightedWord="Platinum"
              isMobile
            />
          )}
        </Grid>
      </Drawer>

      {/* Collections Drawer */}
      <Drawer anchor="left" open={openCollectionsDrawer} onClose={() => setOpenCollectionsDrawer(false)}>
        <Grid container style={{ padding: '20px 0px' }}>
          <Grid xs={12} container alignItems="center">
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <div
                onClick={() => {
                  window.location.href = LogoURL;
                }}
              >
                <SwagUpLogoMobileIcon />
              </div>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '6px' }}>
              <IconButton onClick={() => setOpenCollectionsDrawer(false)}>
                <MobileCloseIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item className={mobileHeaderClasses.content} sx={12}>
            {collectionsData?.map((collection, index) => {
              const formattedName = collection?.slug.toLowerCase().replace(/ /g, '-');
              const link =
                collection.name === collectionsData[0]?.name
                  ? '/catalog/collections'
                  : `/catalog/collections/${formattedName}`;
              return (
                <div
                  key={index}
                  className={mobileHeaderClasses.menuDiv}
                  onClick={() =>
                    handleNavigateWithClose(link, () => {
                      setOpenCollectionsDrawer(false);
                      onClose();
                    })
                  }
                >
                  <Button variant="text" component={Link} to={{ pathname: link }}>
                    <Typography className={mobileHeaderClasses.menuText}>{collection.name}</Typography>
                  </Button>
                  <KeyboardArrowRight />
                </div>
              );
            })}
          </Grid>
        </Grid>
      </Drawer>
      <CategoriesDrawer
        openCategoriesDrawer={openCategoriesDrawer}
        setCategoriesDrawer={setCategoriesDrawer}
        subCategoriesList={subCategoriesList}
        onClose={onClose}
      />
    </>
  );
};

export default MobileMenu;
