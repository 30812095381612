import { shoppingExperienceColors } from '../../shared/constants';

const ProductPageStyles = theme => ({
  gridContainer: {
    padding: '0px 40px 40px 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 11px 11px 11px'
    }
  },
  referenceImages: {
    position: 'absolute',
    top: 200,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: 16
  },
  productTitles: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'left',
    color: shoppingExperienceColors.black
  },
  price: {
    fontFamily: 'Inter',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '-0.03em',
    textAlign: 'left',
    color: shoppingExperienceColors.black
  },
  smallHeadings: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    textAlign: 'left',
    color: shoppingExperienceColors.black
  },
  productImageSkeleton: {
    height: 400,
    width: 450,
    marginTop: 20
  },
  productThumbnailSkeleton: {
    height: 50,
    width: 50,
    marginTop: 20
  },
  dialog: {
    width: 1000,
    maxWidth: 1000,
    maxHeight: 506,
    height: 480,
    borderRadius: 5,
    [theme.breakpoints.down('md')]: {
      width: 800
    },
    [theme.breakpoints.down('sm')]: {
      left: 0,
      position: 'absolute',
      margin: 0,
      bottom: 0,
      width: '100%',
      minHeight: 506
    }
  },
  hazmatWarningContainer: {
    padding: 8,
    border: `1px solid ${shoppingExperienceColors.textPrimary}`,
    borderRadius: 5
  },
  disableQuantityCTA: {
    opacity: 0.2,
    userSelect: 'none',
    pointerEvents: 'none',
    '&:hover': {
      cursor: 'default'
    }
  },
  bulletList: {
    listStyleType: 'none',
    paddingLeft: '10px',
    marginBottom: '0px !important'
  },
  bulletItem: {
    position: 'relative',
    paddingLeft: '16px',
    marginBottom: '8px',
    '&::before': {
      content: '"\\2022"',
      position: 'absolute',
      left: 0,
      color: shoppingExperienceColors.textPrimary,
      fontSize: '20px',
      lineHeight: '1'
    }
  }
});

export default ProductPageStyles;
