import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextLink as FooterLink } from '../../shared/ui/common';
import styles from './footer.styles';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(styles);

const CategoriesOne = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <>
      {currentDevice !== DEVICES_TYPE.MOBILE && (
        <FooterLink variant="h5SemiBoldInter" className={classes.footerSubHeading}>
          Categories
        </FooterLink>
      )}
      <FooterLink
        navigateTo="/catalog"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        All Swag
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('apparel')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Apparel
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('bags')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Bags
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('Bonus Items')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Bonus items
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('drinkware')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Drinkware
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('electronics')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Electronics
      </FooterLink>
      {currentDevice === DEVICES_TYPE.MOBILE && <CategoriesTwo />}
    </>
  );
};

const CategoriesTwo = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <div style={{ paddingTop: currentDevice !== DEVICES_TYPE.MOBILE && '48px' }}>
      <FooterLink
        navigateTo="/catalog/Food & Beverage"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Food & Beverage
      </FooterLink>
      <FooterLink
        navigateTo="/catalog/Home & Games"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Home & Games
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('office')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Office
      </FooterLink>
      <FooterLink
        navigateTo={`/catalog/${encodeURIComponent('packaging')}`}
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Packaging
      </FooterLink>
      <FooterLink
        navigateTo="/catalog/Signs & Displays"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Signs & Displays
      </FooterLink>
    </div>
  );
};

export { CategoriesOne, CategoriesTwo };
