import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  makeStyles,
  InputAdornment,
  Modal
} from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { round } from 'lodash';
import { shoppingExperienceColors } from '../../shared/constants';
import CheckoutFormDatePicker from '../../shared/ui/checkoutFormDatePicker';
import { ToggleBlueSwitch } from '../../shared/ui/common';
import styles from './checkoutPage.styles';
import useResponsive from '../../hooks/useResponsive';
import StyledTextField from '../../shared/ui/styledTextField';
import { VectorIcon } from '../../shared/icons';
import UploadImage from './uploadImage';
import { CommonButton } from '../../shared/ui/commonButtons';
import VectorImage from '../../assets/images/checkoutPage/with-vector-file.png';
import NotVectorImage from '../../assets/images/checkoutPage/without-vector-file.png';
import { CART_TYPES } from '../../utils/constants';
import { setCartInfo, getCartInfo } from '../../sevices/checkout.service';

const useStyles = makeStyles(styles);

const ProjectDetails = ({ isEnabled, setSubmitEnabled, budgetPerPack, budgetError, cartId }) => {
  const classes = useStyles();
  const { DEVICES_TYPE, currentDevice } = useResponsive();
  const [cartType, setCartType] = useState('');
  const isPack = cartType === CART_TYPES.PACK;

  useEffect(() => {
    const storedCartType = localStorage.getItem('cartType');
    if (storedCartType) {
      setCartType(storedCartType);
    }
  }, []);

  const PacksBudgetForm = () => {
    const storageKey = `budget_${cartId}`;
    const persistedBudget = localStorage.getItem(storageKey);
    const defaultBudget = budgetPerPack;
    const [isBudgetModified, setIsBudgetModified] = useState(!!persistedBudget);

    const [formValues, setFormValues] = useState({
      swagPacks: '100',
      budget: persistedBudget || defaultBudget
    });

    const [errors, setErrors] = useState({
      swagPacks: false
    });

    useEffect(() => {
      if (!isBudgetModified) {
        setFormValues(prevState => ({
          ...prevState,
          budget: defaultBudget
        }));
      }
    }, [cartId, defaultBudget, isBudgetModified]);

    useEffect(() => {
      localStorage.setItem(storageKey, formValues.budget);
    }, [formValues.budget, cartId, storageKey]);

    useEffect(() => {
      setCartInfo(formValues);
    }, [formValues]);

    const handleChange = e => {
      const { name, value } = e.currentTarget;

      if (name === 'budget') {
        setIsBudgetModified(true);
        const numericValue = value.replace(/[^0-9.]/g, '');
        setFormValues(prevState => ({
          ...prevState,
          [name]: numericValue
        }));
      } else {
        setFormValues(prevState => ({
          ...prevState,
          [name]: value
        }));
      }

      if (name === 'swagPacks' && value.trim() !== '') {
        setErrors(prevState => ({ ...prevState, swagPacks: false }));
      }
    };

    const handleBlur = e => {
      const { name, value } = e.currentTarget;

      if (name === 'swagPacks' && value.trim() === '') {
        setErrors(prevState => ({ ...prevState, swagPacks: true }));
      }

      if (name === 'budget') {
        const numericValue = round(parseFloat(value), 2);
        const isValidBudget = numericValue > 0;
        setFormValues(prevState => ({
          ...prevState,
          [name]: Number.isNaN(numericValue) || numericValue <= 0 ? defaultBudget : numericValue
        }));
        if (!isValidBudget) {
          setErrors(prevState => ({ ...prevState, budget: true }));
        } else {
          setErrors(prevState => ({ ...prevState, budget: false }));
        }
      }
    };

    return (
      <Grid container spacing={2} className={classes.gridContainer}>
        {/* <Grid item xs={12} md={12} lg={6}>
          <SwagupTypography variant="body3RegularInter" gutterBottom style={{ marginBottom: '16px' }}>
            How many swag packs are you looking to get?*
          </SwagupTypography>
          <StyledTextField
            name="swagPacks"
            id="swagPacks"
            value={formValues.swagPacks}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder="Enter quantity"
            type="number"
            width="100%"
            error={errors.swagPacks}
            helperText={errors.swagPacks ? 'This field is required.' : null}
          />
        </Grid> */}
        <Grid item xs={12} md={12} lg={6}>
          <SwagupTypography variant="body3RegularInter" gutterBottom style={{ marginBottom: '16px' }}>
            What’s your budget per swag pack/person? *
          </SwagupTypography>
          {budgetError && (
            <SwagupTypography variant="body3RegularInter" gutterBottom style={{ marginBottom: '16px' }}>
              {budgetError}
            </SwagupTypography>
          )}
          <StyledTextField
            name="budget"
            id="budget"
            value={formValues.budget}
            onChange={e => {
              const input = e.target.value;

              // Prevent input exceeding 12 characters
              if (input.length <= 12) {
                handleChange(e);
              }
            }}
            onBlur={handleBlur}
            placeholder="Enter budget"
            onWheel={e => e.target.blur()}
            type="number"
            width="100%"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SwagupTypography variant="body1SemiBoldInter" style={{ color: shoppingExperienceColors.black }}>
                    $
                  </SwagupTypography>
                </InputAdornment>
              ),
              classes: {
                input: classes.noPaddingInput
              }
            }}
          />
          {formValues.budget.length >= 12 && (
            <SwagupTypography
              variant="body4MediumInter"
              style={{ color: shoppingExperienceColors.error, marginTop: 4 }}
            >
              Maximum character limit reached
            </SwagupTypography>
          )}
          {!formValues.budget && (
            <SwagupTypography
              variant="body4MediumInter"
              style={{ color: shoppingExperienceColors.error, marginTop: 4 }}
            >
              Field cannot be empty
            </SwagupTypography>
          )}
        </Grid>
      </Grid>
    );
  };

  const DateSelectionField = () => {
    const classes = useStyles();
    const savedCartInfo = getCartInfo();
    const savedDate = savedCartInfo?.date ? new Date(savedCartInfo?.date) : null;
    const savedChecked = !!savedCartInfo?.firm_ihd;
    const [checked, setChecked] = useState(savedChecked);
    const [dateValue, setDateValue] = useState(savedDate);

    const handleToggle = event => {
      const isChecked = !!event?.target?.checked;
      setChecked(isChecked);
      setCartInfo({ firm_ihd: isChecked });
    };

    useEffect(() => {
      setCartInfo({ date: dateValue });
    }, [dateValue]);

    return (
      <Grid container className={classes.datePickerContainer}>
        <Grid item xs={12} className={classes.typographyWrapper}>
          <SwagupTypography variant="body3RegularInter">Do you need these by a certain date?</SwagupTypography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          style={{ paddingRight: currentDevice !== DEVICES_TYPE.MOBILE ? '12px' : '0px' }}
        >
          <CheckoutFormDatePicker setDateValue={setDateValue} initialDate={dateValue} />
          <Grid container>
            <div className={classes.infoRow}>
              <InfoIcon style={{ color: shoppingExperienceColors.primary }} />
              <SwagupTypography variant="body4RegularInter" style={{ color: shoppingExperienceColors.primary }}>
                Our typical turnaround time is 3-4 weeks but orders can be expedited on request.
              </SwagupTypography>
            </div>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={6}
          style={{
            paddingLeft: currentDevice !== DEVICES_TYPE.MOBILE ? '6px' : '0px',
            marginTop: currentDevice === DEVICES_TYPE.DESKTOP ? '0px' : '16px'
          }}
        >
          <div className={classes.toggleRow}>
            <SwagupTypography variant="body3SemiBoldInter">This is a firm date</SwagupTypography>
            <ToggleBlueSwitch checked={checked} onChange={handleToggle} />
          </div>
          {currentDevice !== DEVICES_TYPE.MOBILE && (
            <SwagupTypography variant="body3RegularInter">
              E.g. I need this for a conference or an event
            </SwagupTypography>
          )}
        </Grid>
      </Grid>
    );
  };

  const TextBoxArea = () => {
    const classes = useStyles();
    const [text, setText] = React.useState('');

    const handleChange = event => {
      if (event.target.value.length <= 1000) {
        const { value = '' } = event?.target;
        setText(value);
        setCartInfo({ comments: value });
      }
    };

    return (
      <Grid container style={{ position: 'relative', marginBottom: '20px' }}>
        <SwagupTypography variant="body3RegularInter" style={{ marginBottom: '16px' }}>
          Any items we missed or comments you'd like to add?
        </SwagupTypography>
        <StyledTextField
          width="100%"
          placeholder="Insert card copy, special requests, or anything we missed"
          multiline
          value={text}
          onChange={handleChange}
          className={classes.textBox}
          inputProps={{ maxLength: 1000 }}
        />
        <SwagupTypography variant="body4RegularInter" className={classes.characterCount}>
          {text.length}/1000
        </SwagupTypography>
      </Grid>
    );
  };

  const CouponCode = () => {
    const classes = useStyles();
    const [couponCode, setCouponCode] = React.useState('');

    const handleChange = event => {
      const { value = '' } = event?.target;
      setCouponCode(value);
      setCartInfo({ coupon_code: value });
    };

    return (
      <Grid container style={{ margin: '20px 0px' }}>
        <Grid item md={12} xs={12} lg={6} className={classes.couponContainer}>
          <SwagupTypography variant="body3RegularInter" style={{ marginBottom: '16px' }}>
            Coupon code
          </SwagupTypography>
          <StyledTextField width="100%" placeholder="e.g. SAVE10" value={couponCode} onChange={handleChange} />
          <SwagupTypography
            variant="body4RegularInter"
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              color: shoppingExperienceColors.info
            }}
          >
            <InfoIcon className={classes.infoIcon} />
            Code will be applied after submission
          </SwagupTypography>
        </Grid>
      </Grid>
    );
  };

  const UploadVectorBanner = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLearnMoreClick = () => {
      setIsModalOpen(true);
    };

    const handleModalClose = () => {
      setIsModalOpen(false);
    };

    return (
      <Grid container spacing={2} className={classes.bannerContainer}>
        <Grid item className={classes.iconContainer}>
          <VectorIcon width={30} height={32} />
        </Grid>
        <Grid item className={classes.contentContainer}>
          <SwagupTypography variant="body3MediumInter">
            Upload Vector file for the best quality and faster turnaround
          </SwagupTypography>
          <SwagupTypography variant="body4RegularInter">The file should end in: .svg, .eps, .ai, .pdf</SwagupTypography>
        </Grid>
        <Grid item onClick={handleLearnMoreClick}>
          <SwagupTypography
            variant="subtitle3SemiBoldInter"
            style={{ color: shoppingExperienceColors.primary, cursor: 'pointer' }}
          >
            Learn more
          </SwagupTypography>
        </Grid>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          aria-labelledby="vector-file-modal-title"
          aria-describedby="vector-file-modal-description"
          className={classes.modalContainer}
        >
          <div role="dialog" aria-modal="true">
            <Grid container spacing={3} className={classes.modalContent} justifyContent="center">
              <Grid container alignItems="center" justifyContent="space-between" style={{ marginBottom: '20px' }}>
                <SwagupTypography
                  variant="body3SemiBoldInter"
                  id="vector-file-modal-title"
                  style={{ color: shoppingExperienceColors.primary }}
                >
                  SwagUp Learning
                </SwagupTypography>
                <CloseIcon className={classes.closeIcon} onClick={handleModalClose} />
              </Grid>
              <Grid
                xs={12}
                md={6}
                style={{
                  padding: '0px 20px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <SwagupTypography variant="h3SemiBoldInter" id="learn-more-modal-title">
                  Vector file
                </SwagupTypography>
                <SwagupTypography variant="body3SemiBoldInter">
                  Benefit of providing vector file at this stage
                </SwagupTypography>
                <ul style={{ paddingLeft: '20px', margin: '0px' }}>
                  <li style={{ color: shoppingExperienceColors.textPrimary, fontSize: '20px' }}>
                    <SwagupTypography variant="body3RegularInter">Provides high quality print</SwagupTypography>
                  </li>
                  <li style={{ color: shoppingExperienceColors.textPrimary, fontSize: '20px' }}>
                    <SwagupTypography variant="body3RegularInter">Faster turnaround</SwagupTypography>
                  </li>
                  <li style={{ color: shoppingExperienceColors.textPrimary, fontSize: '20px' }}>
                    <SwagupTypography variant="body3RegularInter">Less hiccups during design process</SwagupTypography>
                  </li>
                </ul>
                <SwagupTypography variant="body3SemiBoldInter">How to recognize a vector file?</SwagupTypography>
                <ul style={{ paddingLeft: '20px' }}>
                  <li style={{ color: shoppingExperienceColors.textPrimary, fontSize: '20px' }}>
                    <SwagupTypography variant="body3RegularInter">
                      The file should end in: .svg, .eps, .ai, .pdf
                    </SwagupTypography>
                  </li>
                </ul>
                {currentDevice !== DEVICES_TYPE.MOBILE && (
                  <CommonButton
                    onClick={handleModalClose}
                    isActive={false}
                    outlined
                    w={84}
                    mt="0px !important"
                    mr="0px !important"
                  >
                    <SwagupTypography variant="buttonMediumInter">Close</SwagupTypography>
                  </CommonButton>
                )}
              </Grid>
              <Grid xs={12} md={6} className={classes.modalImageContainer}>
                <Grid container>
                  <Grid xs={12} md={12}>
                    <img src={VectorImage} alt="With Vector File" className={classes.vectorImage} />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <img src={NotVectorImage} alt="Without Vector File" className={classes.vectorImage} />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Modal>
      </Grid>
    );
  };

  const onFileUpload = data => {
    setSubmitEnabled(!data.isFileUploading);
    if (data?.data) {
      const [primaryLogo = null, secondaryLogo = null] = data.data.map(file => file.path);
      setCartInfo({ primaryLogo, secondaryLogo });
    }
  };

  return (
    <div>
      <Accordion
        expanded={isEnabled}
        disabled={!isEnabled}
        classes={{
          root: classes.accordionRoot,
          disabled: classes.accordionSummaryDisabled
        }}
        className={`${classes.noBorderRadiusTop} ${classes.noBorderRadiusBottom}`}
      >
        <AccordionSummary className={classes.accordionSummary}>
          <SwagupTypography variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body1SemiBoldInter' : 'h5SemiBoldInter'}>
            Project Details
          </SwagupTypography>
        </AccordionSummary>
        <AccordionDetails className={classes.accordionProjectDetails}>
          <Grid container>
            {isPack && <PacksBudgetForm />}
            <DateSelectionField />
            <TextBoxArea />
            <CouponCode />
            <UploadVectorBanner />
            <UploadImage onChange={onFileUpload} />
          </Grid>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default ProjectDetails;
