import React, { useState } from 'react';
import { Grid, Accordion, AccordionSummary, AccordionDetails, Divider } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shoppingExperienceColors } from '../../shared/constants';
import { BackToTop } from '../../shared/ui/common';
import styles from './footer.styles';
import { SwagupLogo } from '../../shared/ui/swagupLogo';
import { useResponsive } from '../../hooks';
import SocialMedia from '../../shared/ui/socialMedia';
import CallUsComponent from '../../shared/ui/callUs';
import { CategoriesOne, CategoriesTwo } from './categories';
import Support from './support';
import CompanyInfo from './companyInfo';
import NewsLetter from './newsLetter';

const useStyles = makeStyles(styles);

const MobileFooter = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = panel => () => {
    setExpanded(expanded === panel ? false : panel);
  };

  const CustomAccordion = ({ title, panelId, expanded, handleChange, children }) => {
    const classes = useStyles();

    return (
      <>
        <Accordion expanded={expanded === panelId} onChange={handleChange(panelId)} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: shoppingExperienceColors.white }} />}
            className={classes.accordionSummary}
          >
            <SwagupTypography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.white }}>
              {title}
            </SwagupTypography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>{children}</AccordionDetails>
        </Accordion>
        <Divider className={classes.divider} />
      </>
    );
  };

  return (
    <div className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <SwagupLogo />
        </Grid>
        <Grid item xs={12} style={{ padding: '40px 0px' }}>
          <NewsLetter />
        </Grid>
        <Grid item xs={12}>
          <CustomAccordion title="Categories" panelId="panel1" expanded={expanded} handleChange={handleChange}>
            <CategoriesOne />
          </CustomAccordion>
        </Grid>
        <Grid item xs={12}>
          <CustomAccordion title="Support" panelId="panel3" expanded={expanded} handleChange={handleChange}>
            <Support />
          </CustomAccordion>
        </Grid>
        <Grid item xs={12}>
          <CustomAccordion title="Company Info" panelId="panel4" expanded={expanded} handleChange={handleChange}>
            <CompanyInfo />
          </CustomAccordion>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" className={classes.footerMediaLink}>
        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
          <SocialMedia />
        </Grid>
      </Grid>
    </div>
  );
};

const TabletFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item xs={2} md={2} style={{ display: 'flex', justifyContent: 'center' }}>
          <SwagupLogo />
        </Grid>
        <Grid item xs={2} md={2}>
          <CategoriesOne />
        </Grid>
        <Grid item xs={3} md={3}>
          <CategoriesTwo />
        </Grid>
        <Grid item xs={2} md={2}>
          <Support />
        </Grid>
        <Grid item xs={3} md={3}>
          <CompanyInfo />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" className={classes.footerMediaLink}>
        <Grid item xs={4} md={3} lg={2}>
          <SocialMedia />
        </Grid>
        <Grid item xs={5} md={6} lg={8}>
          <NewsLetter />
        </Grid>
        <Grid item xs={3} md={3} lg={2}>
          <CallUsComponent showEmoji />
        </Grid>
      </Grid>
    </div>
  );
};

const LaptopFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <SwagupLogo />
        </Grid>
        <Grid item xs={12} md={2}>
          <CategoriesOne />
        </Grid>
        <Grid item xs={12} md={3}>
          <CategoriesTwo />
        </Grid>
        <Grid item xs={12} md={2}>
          <Support />
        </Grid>
        <Grid item xs={12} md={2}>
          <CompanyInfo />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" className={classes.footerMediaLink}>
        <Grid item xs={12} md={3} lg={2}>
          <SocialMedia />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <NewsLetter />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <CallUsComponent showEmoji />
        </Grid>
      </Grid>
    </div>
  );
};

const DesktopFooter = () => {
  const classes = useStyles();

  return (
    <div className={classes.footer}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} lg={3}>
          <SwagupLogo />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <CategoriesOne />
        </Grid>
        <Grid item xs={12} md={4} lg={3}>
          <CategoriesTwo />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <Support />
        </Grid>
        <Grid item xs={12} md={4} lg={2}>
          <CompanyInfo />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" className={classes.footerMediaLink}>
        <Grid item xs={12} md={3} lg={2}>
          <SocialMedia />
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <NewsLetter />
        </Grid>
        <Grid item xs={12} md={3} lg={2}>
          <CallUsComponent showEmoji />
        </Grid>
      </Grid>
    </div>
  );
};

const Footer = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <>
      {currentDevice === DEVICES_TYPE.MOBILE && (
        <>
          <Grid item className={classes.backToTopContainer}>
            <BackToTop />
          </Grid>
          <MobileFooter />
        </>
      )}
      {currentDevice === DEVICES_TYPE.TABLET && <TabletFooter />}
      {currentDevice === DEVICES_TYPE.LAPTOP && <LaptopFooter />}
      {currentDevice === DEVICES_TYPE.DESKTOP && <DesktopFooter />}
    </>
  );
};

export default Footer;
