import { shoppingExperienceColors } from '../../shared/constants';

const CollectionsStyles = theme => ({
  collectionsLayout: {
    padding: '0px 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '0px 11px 11px 11px'
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 8px 8px 8px'
    }
  }
});

export default CollectionsStyles;
