import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { Grid, makeStyles, AppBar, Toolbar, IconButton, Drawer, Backdrop, Typography, Button } from '@material-ui/core';
import { useLocation, useParams } from 'react-router-dom';
import { Provider } from 'react-redux';
import LogRocket from 'logrocket';
import MenuIcon from '@material-ui/icons/Menu';
import Footer from './components/Footer/footer';
import Header from './components/Header/header';
import { getNewURL } from './shared/ui/common';
import { useResponsive } from './hooks';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getSearchParamsObject } from '../helpers/utils';
import { store } from './redux/store';
import { useItemsQuery } from './sevices/service';
import {
  differentHeaderPaths,
  formFooterPaths,
  formHeaderPaths,
  hideFooterPaths,
  MAX_PRODUCT_PER_PAGE,
  removeMarginTopOnIndex
} from './utils/constants';
import SimpleFormHeader from './components/Header/simpleFormHeader';
import SimpleFormFooter from './components/Footer/simpleFormFooter';
// eslint-disable-next-line import/no-named-as-default
import Modal from './shared/ui/modal';
import Login from './pages/Login/login';
import ForgotPassword from './pages/ForgotPassword/forgotPassword';
import { CheckoutContextProvider } from './context/checkout.context';
import { useFilterContext } from './contexts/filter.context';
import Subheader from './components/Header/subHeader';
import { fetchCategories } from './apis';

const useStyles = makeStyles(theme => ({
  dialog: {
    width: 400,
    maxWidth: 400,
    borderRadius: 5,
    paddingBottom: 24,
    [theme.breakpoints.down('md')]: {
      width: 800
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      maxWidth: '100%',
      left: 0,
      position: 'absolute',
      margin: 0,
      bottom: 0,
      minHeight: '100vh'
    }
  },
  drawerStyle: {
    '& .MuiDrawer-paperAnchorTop': { top: 64 },
    '& .MuiPaper-elevation16': {
      boxShadow: 'none'
    }
  },
  backdropStyle: {
    zIndex: 1,
    top: 64,
    position: 'absolute',
    width: '100%',
    height: '100vh',
    '& .MuiBackdrop-root': {
      top: '64px !important'
    }
  }
}));

const DEFAULT_RECORDS_PER_PAGE = 20;

const ShoppingExperience = ({ component: Component = () => <></>, ...props }) => {
  const classes = useStyles();
  const location = useLocation();
  const params = useParams();
  const [width] = useState(window.innerWidth);
  const isTouchDevice = Math.min(width) < 1025 && Math.min(width) > 786;
  const isMobile = Math.min(width) < 786;
  const queryParams = new URLSearchParams(location.search);
  const navigationSource = location.state?.source || null;
  const [openLoginModal, setLoginOpenModal] = useState(false);
  const [openForgotPasswordModal, setForgotPasswordModal] = useState(false);
  const [loadProductsByPageNumber, setLoadProductsByPageNumber] = useState();
  const [groupedFilter, setGroupedFilter] = useState(null);
  const queryString = getSearchParamsObject(location.search);
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('page'), 10) || 1);
  const [directPageNumber] = useState(parseInt(queryParams.get('page'), 10) || 1);
  const [totalPages, setTotalPages] = useState(0);
  const categoryName = props.isCategoryPage ? params.category : '';
  const subCategoryName = props.isCategoryPage ? params.subCategory : '';
  const [sortItem, setSortItem] = useState(null);
  const [defaultEmail, setDefultEmail] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [loadedProducts, setLoadedProducts] = useState({});
  const [drawerOpen, setDrawerOpen] = useState(false);

  const openCategoryDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const { isCategoryPage = false } = props;

  const { appliedFilters = [], delayLoading, setDelayLoading, setAppliedFilter } = useFilterContext();

  const { data, refetch: productsRefetch, isLoading: apiLoading, isFetched } = useItemsQuery(
    MAX_PRODUCT_PER_PAGE,
    MAX_PRODUCT_PER_PAGE * (currentPage - 1),
    groupedFilter || null,
    sortItem,
    [],
    isCategoryPage ? appliedFilters?.length > 0 : true
  );

  const { data: categories, error } = useQuery('categories', fetchCategories);

  useEffect(() => {
    const _appliedFilters = JSON.parse(queryParams.get('appliedFilters') || '[]');
    const sortFields = JSON.parse(queryParams.get('sort') || null);
    setSortItem(sortFields);
    setAppliedFilter(_appliedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!appliedFilters?.length) return;
    const newUrl = getNewURL('appliedFilters', JSON.stringify(appliedFilters), appliedFilters.length === 0);
    const urlObj = new URL(newUrl);
    urlObj.searchParams.delete('page');
    setLoadedProducts({});
    setCurrentPage(1);
    window.history.pushState({}, '', urlObj.toString());
  }, [appliedFilters]);

  const handleSortChange = field => {
    const newUrl = getNewURL('sort', JSON.stringify(field));
    window.history.pushState({}, '', newUrl);
    setSortItem(field);
    productsRefetch();
  };

  const { currentDevice, DEVICES_TYPE } = useResponsive();
  const onClose = (isLoginModal = true) => (isLoginModal ? setLoginOpenModal(false) : setForgotPasswordModal(false));
  const onOpen = (isLoginModal = true) => (isLoginModal ? setLoginOpenModal(true) : setForgotPasswordModal(true));
  useEffect(() => {
    if (data) {
      setTotalPages(Math.ceil(data?.distributorCatalogProducts?.totalProducts / DEFAULT_RECORDS_PER_PAGE));
    }
  }, [data]);

  useEffect(() => {
    let source = sessionStorage.getItem('source');
    if (!source) {
      source = 'page' in queryString ? 'direct' : 'route';
      sessionStorage.setItem('source', source);
    }
    if (loadProductsByPageNumber === undefined)
      setLoadProductsByPageNumber(source === 'direct' && navigationSource === null);
  }, [loadProductsByPageNumber, navigationSource, queryString]);

  const renderHeader = () => {
    if (differentHeaderPaths.includes(location.pathname)) {
      return (
        <Header
          onOpen={onOpen}
          hideMenu
          openCategoryDrawer={openCategoryDrawer}
          drawerOpen={drawerOpen}
          subCategoriesList={categories && Object.values(categories)[0]}
          setDrawerOpen={setDrawerOpen}
        />
      );
    }
    if (formHeaderPaths.includes(location.pathname)) {
      return <SimpleFormHeader onOpen={onOpen} />;
    }
    return (
      <Header
        onOpen={onOpen}
        hideMenu={false}
        openCategoryDrawer={openCategoryDrawer}
        drawerOpen={drawerOpen}
        subCategoriesList={categories && Object.values(categories)[0]}
        setDrawerOpen={setDrawerOpen}
      />
    );
  };

  const renderFooter = () => {
    if (hideFooterPaths.includes(location.pathname)) {
      return null;
    }
    if (formFooterPaths.includes(location.pathname)) {
      return <SimpleFormFooter />;
    }
    return <Footer />;
  };

  // const renderBlueBanner = () => {
  //   return !hideBlueBannerPaths.includes(location.pathname);
  // };

  const isMarginTopRemoved = removeMarginTopOnIndex.includes(location.pathname);

  useEffect(() => {
    if (openLoginModal) {
      localStorage.setItem('currentRoute', location.pathname);
    }
  }, [location.pathname, openLoginModal]);

  useEffect(() => {
    if (apiLoading === false && delayLoading) {
      setTimeout(() => {
        setIsLoading(apiLoading);
        setDelayLoading(false);
      }, 2000);
      return;
    }
    setIsLoading(apiLoading);
  }, [apiLoading, delayLoading, setDelayLoading]);

  useEffect(() => {
    if (window.location.pathname === '/dashboard/start-project') {
      return;
    }

    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has('cartType')) {
      const cartTypeFromUrl = urlParams.get('cartType');
      localStorage.setItem('cartType', cartTypeFromUrl);
      LogRocket.log(`cartType set to ${cartTypeFromUrl} from URL`);
    } else if (!localStorage.getItem('cartType')) {
      LogRocket.warn('No cartType found in URL or localStorage, redirecting to /dashboard/start-project.');
      window.location.href = '/dashboard/start-project';
    } else {
      LogRocket.log('cartType exists in localStorage, not overriding');
    }
  }, []);

  return (
    <Provider store={store}>
      <Grid container style={{ alignItems: 'flex-end' }}>
        <Grid item xs={12}>
          {renderHeader()}
        </Grid>
        <Drawer
          anchor="top"
          open={drawerOpen}
          onClose={openCategoryDrawer}
          variant="temporary"
          ModalProps={{
            keepMounted: true
          }}
          className={classes.drawerStyle}
        >
          <Subheader subCategoriesList={categories} setDrawerOpen={setDrawerOpen} />
        </Drawer>
        <Backdrop open={drawerOpen} onClick={openCategoryDrawer} className={classes.backdropStyle} />
        <Grid
          item
          xs={12}
          style={{
            marginTop: isMarginTopRemoved ? '0px' : currentDevice === DEVICES_TYPE.MOBILE ? '57px' : '80px'
          }}
        >
          {/* // TODO HIDING THE BLUE BANNER CODE */}
          {/* {renderBlueBanner() && (
            <BlueBanner
              bannerText="Looking to build a kit? Add products to your bag"
              bannerLinkText="Learn More"
              bannerLinkURL
            />
          )} */}
          <CheckoutContextProvider>
            <Component
              {...props}
              isTouchDevice={isTouchDevice}
              isMobile={isMobile}
              productList={data}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              directPageNumber={directPageNumber}
              totalPages={totalPages}
              navigationSource={navigationSource}
              loadProductsByPageNumber={loadProductsByPageNumber}
              setLoadProductsByPageNumber={setLoadProductsByPageNumber}
              categoryName={categoryName}
              subCategoryName={subCategoryName}
              productsRefetch={productsRefetch}
              sortItem={sortItem}
              groupedFilter={groupedFilter}
              setGroupedFilter={setGroupedFilter}
              handleSortChange={handleSortChange}
              isLoading={isLoading}
              onOpen={onOpen}
              setDefultEmail={setDefultEmail}
              isFetched={isFetched}
              loadedProducts={loadedProducts}
              setLoadedProducts={setLoadedProducts}
              setIsLoading={setIsLoading}
            />
          </CheckoutContextProvider>
        </Grid>
        {renderFooter() && <>{renderFooter()}</>}
      </Grid>
      <Modal title="" open={openLoginModal} onClose={onClose} innerClasses={{ paper: classes.dialog }}>
        <Login onClose={onClose} onOpen={onOpen} defaultEmail={defaultEmail} />
      </Modal>
      <Modal
        title=""
        open={openForgotPasswordModal}
        onClose={() => onClose(false)}
        innerClasses={{ paper: classes.dialog }}
      >
        <ForgotPassword onClose={onClose} onOpen={onOpen} />
      </Modal>
    </Provider>
  );
};

export default ShoppingExperience;
