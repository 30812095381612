import { useQuery } from 'react-query';
import { fetchDistributorCatalogProducts, fetchDistributorCatalogProductsForCart, fetchProductFilter } from '../apis';
import { sortData } from '../utils/constants';

export const useItemsQuery = (take, skip, appliedFilters = null, sort = null, productCodes = [], condition = null) => {
  return useQuery(
    ['data', take, skip, appliedFilters, sort, productCodes],
    () =>
      fetchDistributorCatalogProducts(
        take,
        skip,
        appliedFilters == null ? [] : appliedFilters,
        sort === null ? sortData[0].values[0].parameter : sort,
        productCodes
      ),
    {
      enabled: condition !== null ? condition : true,
      keepPreviousData: true
    }
  );
};

export const useProductFilterQuery = (appliedFilters = null) => {
  return useQuery(['data', appliedFilters], () =>
    fetchProductFilter(appliedFilters == null ? [] : appliedFilters, null)
  );
};

export const useProductFilterQueryForCollection = (appliedFilters = null, collectionId) => {
  return useQuery(
    ['data', appliedFilters],
    () => fetchProductFilter(appliedFilters == null ? [] : appliedFilters, collectionId),
    {
      enabled: !!collectionId
    }
  );
};

export const useCartsQuery = (productCodes = []) => {
  return useQuery(['data', productCodes], () => fetchDistributorCatalogProductsForCart(productCodes));
};
