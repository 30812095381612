import { useCallback } from 'react';
import { round } from 'lodash';
import { useMembership } from '../../contexts/membershipContext';

const useDiscountPrice = () => {
  const { currentMembership } = useMembership();
  const productDiscount = currentMembership?.product_discount;

  const getDiscountPrice = useCallback(
    (price, discount = 'product') => {
      const currentDicount = currentMembership[`${discount}_discount`];
      if (!currentDicount) return price;
      return round(price - (price * currentDicount) / 100, 2);
    },
    [currentMembership]
  );

  const getPriceByShippingAndMarkup = useCallback((quantity, currentPrice) => {
    return currentPrice * quantity;
  }, []);

  return {
    getDiscountPrice,
    getPriceByShippingAndMarkup,
    productDiscount
  };
};

export default useDiscountPrice;
