import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Skeleton from '@material-ui/lab/Skeleton';

const CollectionsBannerImage = ({ imageUrl, width, height }) => {
  const resolvedWidth = typeof width === 'number' ? `${width}px` : width;
  const resolvedHeight = typeof height === 'number' ? `${height}px` : height;
  const [loaded, setLoaded] = useState(false);

  const containerStyle = {
    position: 'relative',
    width: resolvedWidth,
    height: resolvedHeight,
    overflow: 'hidden',
    minHeight: `${resolvedHeight} !important`,
    aspectRatio: '1 / 1 !important'
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    minHeight: `${resolvedHeight} !important`,
    aspectRatio: '1 / 1 !important'
  };

  return (
    <Grid container style={containerStyle}>
      {!loaded && <Skeleton variant="rect" width={resolvedWidth} height={resolvedHeight} />}
      <LazyLoadImage
        src={imageUrl}
        alt="Collections Banner"
        effect="blur"
        width={resolvedWidth}
        height={resolvedHeight}
        onLoad={() => setLoaded(true)}
        style={imageStyle}
      />
    </Grid>
  );
};

export default CollectionsBannerImage;
