/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent, CardMedia, Grid, IconButton, makeStyles, Box, Tooltip } from '@material-ui/core';
import { Add, Remove } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import styles from './cartProduct.styles';
import { useCartContext } from '../../context/cart.context';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import StrikeOut from '../../shared/ui/StrikeOut';
import { MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK } from '../../utils/constants';
import MembershipPricing from '../../shared/ui/membershipPricing';
import { CapitalizeWords, DollarPriceSymbol } from '../../shared/ui/common';

const useStyles = makeStyles(styles);

const CartPopup = props => {
  const classes = useStyles();
  const history = useHistory();
  const {
    details,
    isMobile,
    isEditQuantity = false,
    handleQuantityChange = () => {},
    isShowPricePerItem,
    isConfirmation = false
  } = props;
  const [productQuantity, setProductQuantity] = useState(props?.details?.quantity || 0);
  const { isPack, qtyForPack, getWithAndWithoutDiscountedPricePerProduct } = useCartContext();
  const { productDiscount } = useDiscountPrice();
  const { isAuthenticated } = useAuth();

  const applicableQuantity = useMemo(() => {
    return isPack ? details?.quantity * qtyForPack : details?.quantity;
  }, [isPack, details?.quantity, qtyForPack]);

  const [message, setMessage] = useState('');

  const preventIncreasingQuantity = useMemo(() => {
    let currentMessage = '';

    if (details?.isHazmat === true) {
      currentMessage = "You can't add another hazmat product to your pack.";
    } else if (productQuantity >= MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK) {
      currentMessage = 'You reached the maximum quantity allowed for this product per pack.';
    }

    setMessage(currentMessage);

    return isPack === true && !!currentMessage;
  }, [details, isPack, productQuantity]);

  const preventDecreasingQuantity = useMemo(() => {
    return productQuantity === 1;
  }, [productQuantity]);

  const handleQuantityValueChange = (value, preventUpdate = false) => {
    let numericValue = +value;
    numericValue = numericValue === 0 ? 1 : numericValue;
    if (preventUpdate) {
      if (preventIncreasingQuantity && (numericValue < 1 || numericValue > MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK)) {
        numericValue = numericValue >= 10 ? 10 : 1;
      } else if (preventDecreasingQuantity && value < details.quantity) {
        numericValue = +value;
      }
      handleQuantityChange(numericValue > 1 ? numericValue : 1);
    }

    setProductQuantity(
      isPack
        ? numericValue > MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK
          ? MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK
          : numericValue
        : numericValue
    );
  };

  const handleGoToProductDetails = () => {
    const url = `/catalog/product/${details.name}/${details.productCode}?selectedColor=${encodeURIComponent(
      details.color
    )}`;
    history.push(url);
  };

  const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(details);

  const pricePerItem = isPack ? totalPriceWithDiscount / qtyForPack : totalPriceWithDiscount / details?.quantity;

  const isWhite = ['#ffffff', '#FFFFFF', 'white', 'White'];

  return (
    <Card
      className={classes.cardContainer}
      style={{
        borderRight: isConfirmation || (isEditQuantity && 'none') ? 'none' : undefined,
        minHeight: isConfirmation && 210
      }}
    >
      {' '}
      <CardMedia
        className={classes.cardMediaStyle}
        image={details?.thumbnailURL || ''}
        onClick={handleGoToProductDetails}
      />
      <CardContent className={classes.cardContent} style={{ width: isMobile && '100%' }}>
        <div style={{ marginBottom: 20, cursor: 'pointer' }} onClick={handleGoToProductDetails}>
          {isMobile && !isConfirmation ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2SemiBoldInter">{details?.name}</Typography>
            </div>
          ) : (
            <Typography variant="body2SemiBoldInter">{details?.name}</Typography>
          )}
          {!isConfirmation && details?.brand && <Typography variant="body3RegularInter">{details?.brand} </Typography>}
        </div>
        <div>
          <Grid container className={classes.productDetails} justifyContent="flex-start" alignItems="center">
            <Typography variant="body3RegularInter">Color: {CapitalizeWords(details?.colorName)}</Typography>
            <div
              style={{
                width: 18,
                height: 18,
                background: `#${details?.color}`,
                borderRadius: '50%',
                border: `${isWhite.includes(`#${details?.color}`) && `1px solid black`}`,
                marginRight: 4,
                marginLeft: 4
              }}
            />
          </Grid>
          {!isConfirmation && (
            <Typography
              variant="body3RegularInter"
              className={classes.productDetails}
              style={{ marginBottom: isMobile && '10px' }}
            >
              Decoration method: {details?.decorationMethod}{' '}
            </Typography>
          )}
          {isShowPricePerItem &&
            (isAuthenticated ? (
              <div>
                <MembershipPricing price={details?.price} quantity={productQuantity} product={details} />
              </div>
            ) : (
              <div style={{ display: 'flex', width: '100%', alignItems: 'center', paddingBottom: '10px' }}>
                <Typography variant="body2SemiBoldInter">
                  {DollarPriceSymbol(details?.price * productQuantity)}
                </Typography>
                <Typography variant="body3RegularInter" style={{ paddingLeft: '10px' }}>
                  {DollarPriceSymbol(pricePerItem)} / item
                </Typography>
              </div>
            ))}
          {isEditQuantity ? (
            <>
              <Typography
                variant="body3RegularInter"
                className={classes.productDetails}
                style={{ marginBottom: isMobile && '4px' }}
              >
                {`Quantity ${isPack ? 'per pack' : ''}`}:
              </Typography>
              {preventIncreasingQuantity ? (
                <Tooltip placement="right" title={message} arrow>
                  <Box display="flex" alignItems="center" mt={1} mb={4} style={{ maxWidth: 190 }}>
                    <IconButton
                      style={{ padding: isMobile && '0px' }}
                      className={preventDecreasingQuantity ? classes.disableQtyBtn : {}}
                      onClick={() => {
                        handleQuantityValueChange(productQuantity - 1, true);
                      }}
                      disableRipple={productQuantity <= 1 || preventIncreasingQuantity}
                    >
                      <Remove />
                    </IconButton>
                    <input
                      type="text"
                      id="quantity-field"
                      value={productQuantity}
                      onChange={e => handleQuantityValueChange(e.target.value)}
                      onInput={e => {
                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                      }}
                      onBlur={e => {
                        handleQuantityValueChange(e.target.value, true);
                      }}
                      disabled={preventIncreasingQuantity}
                      style={{
                        width: 60,
                        textAlign: 'center',
                        padding: '8px',
                        border: '1px solid gray',
                        borderRadius: 4,
                        margin: !isMobile ? '0 16px' : '0px 2px'
                      }}
                    />
                    <IconButton
                      style={{ padding: isMobile && '0px' }}
                      className={preventIncreasingQuantity ? classes.disableQtyBtn : {}}
                      onClick={() => {
                        handleQuantityValueChange(productQuantity + 1, true);
                      }}
                      disableRipple={preventIncreasingQuantity}
                    >
                      <Add />
                    </IconButton>
                  </Box>
                </Tooltip>
              ) : (
                <Box display="flex" alignItems="center" mt={1} mb={4} style={{ maxWidth: 190 }}>
                  <IconButton
                    style={{ padding: isMobile && '0px' }}
                    className={preventDecreasingQuantity ? classes.disableQtyBtn : {}}
                    onClick={() => {
                      handleQuantityValueChange(productQuantity - 1, true);
                    }}
                    disableRipple={productQuantity <= 1}
                  >
                    <Remove />
                  </IconButton>
                  <input
                    type="text"
                    id="quantity-field"
                    value={productQuantity}
                    onChange={e => handleQuantityValueChange(e.target.value)}
                    onInput={e => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    onBlur={e => {
                      handleQuantityValueChange(e.target.value, true);
                    }}
                    disabled={preventIncreasingQuantity}
                    style={{
                      width: 60,
                      textAlign: 'center',
                      padding: '8px',
                      border: '1px solid gray',
                      borderRadius: 4,
                      margin: !isMobile ? '0 16px' : '0px 2px'
                    }}
                  />
                  <IconButton
                    style={{ padding: isMobile && '0px' }}
                    className={preventIncreasingQuantity ? classes.disableQtyBtn : {}}
                    onClick={() => {
                      handleQuantityValueChange(productQuantity + 1, true);
                    }}
                    disableRipple={preventIncreasingQuantity}
                  >
                    <Add />
                  </IconButton>
                </Box>
              )}
            </>
          ) : (
            <>
              <Typography variant="body3RegularInter" className={classes.productDetails}>
                Quantity: {`${applicableQuantity} - ${DollarPriceSymbol(pricePerItem)} / Item`}
              </Typography>
              {isAuthenticated && productDiscount !== 0 ? (
                <Grid container style={{ gap: 8 }} className={classes.productDetails}>
                  <Typography variant="body3SemiBoldInter" style={isMobile ? { marginBottom: 0 } : {}}>
                    {DollarPriceSymbol(totalPriceWithDiscount)}
                  </Typography>
                  <StrikeOut value={totalPriceWithoutDiscount} />
                </Grid>
              ) : (
                <Typography
                  variant="body3SemiBoldInter"
                  className={classes.productDetails}
                  style={isMobile ? { marginBottom: 0 } : {}}
                >
                  {DollarPriceSymbol(totalPriceWithoutDiscount)}
                </Typography>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
};
export default CartPopup;
