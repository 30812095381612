import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, Grid, TextField, InputAdornment, Switch, Paper } from '@material-ui/core';
import { Typography as SwagupTypography, Button } from '@swagup-com/components';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { round } from 'lodash';
import styles from './common.styles';
import { UpArrowIcon } from '../icons';
import { shoppingExperienceColors } from '../constants';
import ProductImage from '../../assets/images/products/product_thumb.png';
import useResponsive from '../../hooks/useResponsive';

const useStyles = makeStyles(styles);

const TextLink = ({ target = '_self', className, style, variant, href, children, navigateTo }) => {
  const history = useHistory();

  const openIntercomHelp = () => {
    console.log('Opening Intercom Chatbot');
    if (typeof Intercom !== 'undefined') {
      Intercom('showSpace', 'home');
    }
  };

  const handleOnClick = event => {
    if (children === 'Chat with us') {
      event.preventDefault();
      openIntercomHelp();
    } else if (navigateTo) {
      event.preventDefault();
      history.push(navigateTo);
    }
  };

  if (!href && !navigateTo && children !== 'Chat with us') {
    return (
      <SwagupTypography variant={variant} className={className} style={style}>
        {children}
      </SwagupTypography>
    );
  }

  return (
    <Link
      href={href || '#'}
      target={target}
      color="inherit"
      underline="none"
      rel="noopener noreferrer"
      onClick={children === 'Chat with us' || navigateTo ? handleOnClick : undefined}
    >
      <SwagupTypography
        variant={variant}
        className={className}
        style={children === 'Chat with us' ? { ...style, cursor: 'pointer' } : style}
      >
        {children}
      </SwagupTypography>
    </Link>
  );
};

const ImageLink = ({
  imageSrc,
  IconComponent,
  linkUrl,
  className,
  style,
  target = '_self',
  rel = 'noopener noreferrer',
  alt = 'logo'
}) => {
  const classes = useStyles();

  return (
    <a href={linkUrl} target={target} rel={rel}>
      {imageSrc ? (
        <img src={imageSrc} className={`${classes.image} ${className}`} style={style} alt={alt} />
      ) : IconComponent ? (
        <IconComponent className={className} style={style} alt={alt} />
      ) : null}
    </a>
  );
};

const BackToTop = () => {
  const classes = useStyles();
  const [isVisible, setIsVisible] = useState(false);

  const checkScrollPosition = () => {
    const scrollPosition = window.scrollY + window.innerHeight;
    const pageHeight = document.documentElement.scrollHeight;

    if (scrollPosition > pageHeight / 2) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition);
    return () => window.removeEventListener('scroll', checkScrollPosition);
  }, []);

  return (
    <Grid onClick={scrollToTop} className={classes.buttonGrid}>
      <UpArrowIcon />
      <Button className={`${classes.backToTopButton} ${isVisible ? classes.showButton : ''}`} variant="text">
        <SwagupTypography variant="subtitle3SemiBoldInter">Back to top</SwagupTypography>
      </Button>
    </Grid>
  );
};

const NavigateTo = (history, path) => {
  history.push(path);
};

const PageTitle = (location, collections = []) => {
  const pathSegments = location.pathname.split('/');
  let path = pathSegments.filter(segment => segment !== '');
  path = path.length > 0 ? path[path.length - 1] : '';
  path = path.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  const title = path.map(pth => pth.charAt(0).toUpperCase() + pth.slice(1));
  if (location.pathname === '/catalog/collections') {
    const firstCollectionName = collections?.[0]?.name || 'Best Sellers';
    return `Shop Our ${FormatText(firstCollectionName)}`;
  }
  if (pathSegments.includes('collections')) {
    title.unshift('Our');
  }

  return title[0] === 'Catalog' ? 'All Swag' : title.join(' ');
};

const TitleFormatter = name => {
  const path = name.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  const title = path.map(pth => pth.charAt(0).toUpperCase() + pth.slice(1));
  return title.join(' ');
};

const UrlStringFormatter = (string, url = null) => {
  return (
    <div>
      {string.split(' ').map((word, index) => {
        const pos = url.findIndex(item => item.keyword === word);
        if (pos != -1) {
          return (
            <a key={index} style={{ color: '#000000' }} href={url[pos]?.path} target="_blank" rel="noopener noreferrer">
              <span style={{ textDecoration: 'underline' }}>{`${word} `}</span>
            </a>
          );
        }
        return <span key={index}>{word} </span>;
      })}
    </div>
  );
};

const CalculateSkipAndTake = (
  PAGE_NUMBER,
  DEFAULT_RECORDS_PER_PAGE,
  loadProductsByPageNumber,
  setTotalRecordsToFetch,
  setTake,
  setSkip,
  directPageNumber,
  totalPages
) => {
  const pageNumber = Number.isNaN(PAGE_NUMBER) || PAGE_NUMBER < 1 ? 1 : PAGE_NUMBER;
  const _totalRecordsToFetch = DEFAULT_RECORDS_PER_PAGE * pageNumber;
  const _skip = loadProductsByPageNumber ? DEFAULT_RECORDS_PER_PAGE * (totalPages - pageNumber) : 0;
  const _take = loadProductsByPageNumber
    ? DEFAULT_RECORDS_PER_PAGE * (pageNumber < directPageNumber ? pageNumber : pageNumber)
    : _totalRecordsToFetch;
  setTotalRecordsToFetch(_totalRecordsToFetch);
  setTake(_take);
  setSkip(_skip);
};

const getNewURL = (field, value, isDeleteField = false) => {
  const baseUrl = window.location.origin + window.location.pathname;

  const urlParams = new URLSearchParams(window.location.search);

  urlParams.delete(field);
  if (!isDeleteField) {
    urlParams.set(field, value);
  }

  return `${baseUrl}${urlParams.toString() ? `?${urlParams.toString()}` : ''}`;
};

const HandleNextPage = currentPage => {
  const nextPage = currentPage + 1;
  const newUrl = getNewURL('page', nextPage);

  window.history.pushState({}, '', newUrl);
  return nextPage;
};

const HandlePreviousPage = currentPage => {
  const prevPage = Math.max(currentPage - 1, 1);
  const newUrl = getNewURL('page', prevPage);
  window.history.pushState({}, '', newUrl);
  return prevPage;
};

const GetMinimumPrice = data => {
  if (data?.length) return Math.min(...data[0]?.pricingMatrix?.map(item => item.price[0].value));
};

const GetMediaUrls = media => {
  if (!media || !media.length) {
    return {
      thumbnailImage: ProductImage,
      realImage: ProductImage
    };
  }

  const firstMedia = media.find(item => item.mediaCategory === 'product') || media[0];
  const realMedia = media.find(item => item.mediaCategory === 'lifestyle');
  return {
    realImage: realMedia ? realMedia.url : firstMedia.url,
    thumbnailImage: firstMedia.url
  };
};

const BlueBanner = ({ bannerText, bannerLinkText, bannerLinkURL = '#' }) => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <Grid container justifyContent="center" alignItems="center" className={classes.blueBanner}>
      {currentDevice === DEVICES_TYPE.MOBILE ? (
        <a href={bannerLinkURL} target="_self" rel="noopener noreferrer" className={classes.blueBannerLinkMobile}>
          <SwagupTypography variant="body2RegularInter" style={{ color: shoppingExperienceColors.white }}>
            {bannerText}
          </SwagupTypography>
        </a>
      ) : (
        <>
          <SwagupTypography variant="body2RegularInter" style={{ color: shoppingExperienceColors.white }}>
            {bannerText}
          </SwagupTypography>
          <a href={bannerLinkURL} target="_self" rel="noopener noreferrer" className={classes.blueBannerLink}>
            <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.white }}>
              {bannerLinkText}
            </SwagupTypography>
          </a>
        </>
      )}
    </Grid>
  );
};

const TruncateText = (text, maxLength) => {
  return text?.length > maxLength ? `${text?.slice(0, maxLength)}...` : text;
};

const FormatText = text => {
  if (text) {
    return text
      .split(/[-+]/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }
};

const CapitalizeWords = str => {
  return str?.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const ToggleBlueSwitch = withStyles({
  root: {
    overflow: 'visible'
  },
  switchBase: {
    color: shoppingExperienceColors.disabledCta,
    '&$checked': {
      color: shoppingExperienceColors.primary
    },
    '&$checked + $track': {
      backgroundColor: shoppingExperienceColors.toggleblueSwitchTrack,
      opacity: 1
    }
  },
  checked: {},
  track: {
    backgroundColor: shoppingExperienceColors.disabledCta
  },
  thumb: {
    boxShadow: 'none'
  }
})(Switch);

const DollarPriceSymbol = (value, decimals = 2) => {
  const roundedValue = round(value ?? 0, decimals);
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: decimals
  }).format(roundedValue);
};

const MembershipDiscountTextBadge = ({ text, typographyVariant }) => {
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.rootPaper}>
      <SwagupTypography variant={typographyVariant} style={{ color: shoppingExperienceColors.primary }}>
        {text}
      </SwagupTypography>
    </Paper>
  );
};

export {
  TextLink,
  ImageLink,
  BackToTop,
  NavigateTo,
  PageTitle,
  TitleFormatter,
  UrlStringFormatter,
  CalculateSkipAndTake,
  HandleNextPage,
  HandlePreviousPage,
  GetMinimumPrice,
  GetMediaUrls,
  BlueBanner,
  TruncateText,
  FormatText,
  CapitalizeWords,
  ToggleBlueSwitch,
  DollarPriceSymbol,
  MembershipDiscountTextBadge,
  getNewURL
};
