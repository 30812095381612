import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, makeStyles, InputAdornment } from '@material-ui/core';
import { Typography as SwagupTypography, Button } from '@swagup-com/components';
import subscribeToNewsletter from '../../apis/subscribeToNewsletter';
import { emailRequired, getSchema } from '../../../components/global/Forms/commonValidations';
import { shoppingExperienceColors } from '../../shared/constants';

const resolver = yupResolver(getSchema([], { email: emailRequired }));

const useStyles = makeStyles(() => ({
  textField: {
    width: 'auto',
    borderRadius: '100px',
    backgroundColor: shoppingExperienceColors.black,
    '& .MuiOutlinedInput-adornedEnd': {
      backgroundColor: `${shoppingExperienceColors.white} !important`
    }
  },
  placeholder: {
    fontFamily: 'Inter !important',
    color: shoppingExperienceColors.textPrimary,
    borderTopRightRadius: '0px !important',
    borderBottomRightRadius: '0px !important',
    backgroundColor: `${shoppingExperienceColors.white} !important`
  },
  button: {
    height: '36px',
    width: '125px',
    fontFamily: 'Inter',
    fontWeight: 600,
    lineHeight: '20px',
    backgroundColor: `${shoppingExperienceColors.white} !important`,
    border: 'none',
    color: `${shoppingExperienceColors.black} !important`,
    '&:hover': {
      color: `${shoppingExperienceColors.black} !important`,
      backgroundColor: shoppingExperienceColors.ctaHover
    }
  },
  '&.MuiOutlinedInput-notchedOutline': {
    backgroundColor: `${shoppingExperienceColors.black} !important`,
    borderColor: shoppingExperienceColors.black
  }
}));

const NewsletterInput = () => {
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(null);
  const {
    formState: { errors },
    register,
    handleSubmit
  } = useForm({ mode: 'all', resolver });

  const clearMessageAfterTimeout = () => {
    setTimeout(() => {
      setMessage('');
    }, 8000);
  };

  const extractFirstName = email => {
    return email.split('@')[0];
  };

  const onSubmit = async data => {
    try {
      const first_name = extractFirstName(data.email);
      const response = await subscribeToNewsletter(data.email, first_name);
      if (response.status === 200) {
        setMessage('Subscription successful!');
        setIsSuccess(true);
      } else {
        setMessage('Failed to subscribe, please try again.');
        setIsSuccess(false);
      }
    } catch (error) {
      if (error?.status === 400 && error?.title) {
        setMessage(error?.title);
      } else {
        setMessage('An error occurred. Please try again.');
      }
      setIsSuccess(false);
    }
    clearMessageAfterTimeout();
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          {...register('email')}
          className={classes.textField}
          variant="outlined"
          placeholder="Enter your email"
          error={!!errors.email}
          helperText={errors.email?.message}
          InputProps={{
            classes: {
              input: classes.placeholder
            },
            endAdornment: (
              <InputAdornment position="end">
                <Button type="submit" className={classes.button}>
                  <SwagupTypography
                    variant="body3SemiBoldInter"
                    style={{
                      color: !message || isSuccess ? shoppingExperienceColors.black : shoppingExperienceColors.error
                    }}
                  >
                    Subscribe
                  </SwagupTypography>
                </Button>
              </InputAdornment>
            )
          }}
        />
      </form>

      {message && (
        <SwagupTypography
          variant="body4RegularInter"
          style={{
            textAlign: 'right',
            color: isSuccess ? shoppingExperienceColors.success : shoppingExperienceColors.error
          }}
        >
          {message}
        </SwagupTypography>
      )}
    </>
  );
};

export default NewsletterInput;
