import React from 'react';
import { Grid } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { shoppingExperienceColors } from '../../shared/constants';
import { useResponsive } from '../../hooks';
import NewsletterInput from './newsletterInput';

const NewsLetter = () => {
  const { isMobileOrTabletOrLaptop } = useResponsive();

  return (
    <Grid container alignItems="center" justifyContent="center" style={{ gap: '10px', display: 'grid' }}>
      {!isMobileOrTabletOrLaptop && (
        <SwagupTypography
          variant="h6SemiBoldInter"
          style={{ color: shoppingExperienceColors.white, fontWeight: 500, textAlign: 'center' }}
        >
          Be the first to know
        </SwagupTypography>
      )}
      <NewsletterInput />
    </Grid>
  );
};

export default NewsLetter;
