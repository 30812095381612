import { request, gql } from 'graphql-request';
import { apiRoutes } from './apiRoutes';

export const fetchCatalogProductDetails = async productCode => {
  const variables = {
    productCode
  };

  const GET_PRODUCT_DETAILS = gql`
    query GetProductDetails($productCode: String!) {
      distributorCatalogProductDetails(productCode: $productCode) {
        _id
        name
        brand
        canBeExported
        decorationOptions
        productCode
        minimumOrderQuantity
        description
        status
        inventoryStatus
        categories {
          name
          subCategories
        }
        hazmatDetails {
          isHazmat
        }
        markupOption {
          type
          markupMatrix {
            parameters {
              name
              minimum
              maximum
              type
              value
            }
            markup {
              currency
              value
              pricingUnitOfMeasurement
            }
          }
        }
        shipping {
          type
          currency
          value
          unitOfMeasure
        }
        line
        ecoFriendly
        material
        recyclable
        media {
          color
          name
          url
          mediaCategory
        }
        variants {
          media {
            url
            color
            name
            mediaCategory
          }
          pricingOption {
            pricingMatrix {
              parameters {
                value
                minimum
                maximum
              }
              price {
                value
              }
            }
          }
          color {
            colorName
            hex
            isPrimary
            standardColorName
            approximatePms
          }
        }
        hazmatDetails {
          isHazmat
        }
      }
    }
  `;

  return request(
    `${process.env.REACT_APP_API_GATEWAY_ENDPOINT}${apiRoutes.getDistributorCatalogs}`,
    GET_PRODUCT_DETAILS,
    variables,
    {
      'x-access-token': process.env.REACT_APP_API_GATEWAY_ACCESS_CODE
    }
  ).then(data => {
    return data;
  });
};
