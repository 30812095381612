/* eslint-disable react/react-in-jsx-scope */
import { CardContent, Grid } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { Typography } from '@swagup-com/components';
import useResponsive from '../../hooks/useResponsive';
import { shoppingExperienceColors } from '../../shared/constants';
import CartProduct from './cartProduct';
import { useCartContext } from '../../context/cart.context';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import MembershipPricing from '../../shared/ui/membershipPricing';
import { DollarPriceSymbol } from '../../shared/ui/common';

const CartProductItem = ({ product, IS_PACK = false }) => {
  const { price, cartId } = product;
  const { isMobile } = useResponsive();
  const { isAuthenticated } = useAuth();
  const { productDiscount } = useDiscountPrice();

  const {
    isPack,
    qtyForPack,
    updateCart,
    removeCartItem,
    getWithAndWithoutDiscountedPricePerProduct
  } = useCartContext();

  const onHandleQuantityChange = updatedQuantity => {
    updateCart(product.cartId, updatedQuantity);
  };

  const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(product);

  const pricePerItem = isPack ? totalPriceWithDiscount / qtyForPack : totalPriceWithDiscount / product?.quantity;

  return (
    <CardContent
      style={{ borderBottom: `1px solid ${shoppingExperienceColors.borderLineGray}`, padding: isMobile ? 4 : 16 }}
    >
      <Grid container xs={12} md={12} sm={12} lg={12} justifyContent="space-between">
        <Grid item xs={isMobile ? 12 : 9} md={8} sm={8} lg={8}>
          <CartProduct
            details={product}
            isMobile={isMobile}
            isShowPricePerItem={isMobile}
            isEditQuantity
            handleQuantityChange={onHandleQuantityChange}
            IS_PACK={IS_PACK}
          />
        </Grid>
        {!isMobile && (
          <Grid
            item
            style={{ textAlign: 'end', paddingTop: isAuthenticated && productDiscount !== 0 ? '12px' : '16px' }}
            xs={3}
            md={4}
            sm={4}
            lg={4}
          >
            {isAuthenticated && productDiscount !== 0 ? (
              <div>
                <MembershipPricing price={price} quantity={product.quantity} product={product} />
              </div>
            ) : (
              <>
                <Typography variant="body2SemiBoldInter">{DollarPriceSymbol(totalPriceWithoutDiscount)}</Typography>
                <Typography variant="body3RegularInter" style={{ paddingTop: '10px' }}>
                  {DollarPriceSymbol(pricePerItem)} / item
                </Typography>
              </>
            )}
            <div style={{ paddingTop: '10px', cursor: 'pointer' }}>
              <Delete style={{ color: shoppingExperienceColors.textPrimary }} onClick={() => removeCartItem(cartId)} />
            </div>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

export default CartProductItem;
