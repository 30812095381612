import React from 'react';
import { makeStyles } from '@material-ui/core';
import { TextLink as FooterLink } from '../../shared/ui/common';
import styles from './footer.styles';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(styles);

const CompanyInfo = () => {
  const classes = useStyles();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  return (
    <>
      {currentDevice !== DEVICES_TYPE.MOBILE && (
        <FooterLink variant="h5SemiBoldInter" className={classes.footerSubHeading}>
          Company Info
        </FooterLink>
      )}
      <FooterLink
        target="_blank"
        href="https://www.swagup.com/membership"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Memberships
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://www.swagup.com/fulfillment-performance"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Fulfillment Times
      </FooterLink>
      <FooterLink
        target="_black"
        href="https://www.swagup.com/services"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Services
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://www.swagup.com/pricing"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Pricing
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://www.swagup.com/privacy-policy-dba"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Privacy
      </FooterLink>
      <FooterLink
        target="_blank"
        href="https://www.swagup.com/terms-of-use"
        variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body3RegularInter' : 'subtitle3RegularInter'}
        className={classes.footerSubText}
      >
        Terms of Use
      </FooterLink>
    </>
  );
};

export default CompanyInfo;
