import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { useQuery } from 'react-query';
import { shoppingExperienceColors } from '../shared/constants';
import { Helmet } from '../../components/shared';
import { CapitalizeWords, HandleNextPage, HandlePreviousPage } from '../shared/ui/common';
import { fetchSearchResults } from '../apis/searchApi';
import { MAX_PRODUCT_PER_PAGE } from '../utils/constants';
import Product from '../components/Product/product';
import PaginationButton from '../shared/ui/paginationButton';
import CartPopup from '../components/CartPopup/cartPopup';
import Modal from '../shared/ui/modal';
import Pagination from '../components/Pagination/pagination';
import { prepareDataForCartPopup } from '../utils/cartUtil';
import FilterButton from '../components/FilterAndSortButton/filterAndSortButton';

const SearchResults = props => {
  const { handleSortChange, sortItem } = props;
  const location = useLocation();
  const width = window.innerWidth;
  const params = new URLSearchParams(location.search);
  const query = params.get('q');
  const [currentPage, setCurrentPage] = useState(parseInt(params.get('page')) || 1);
  const [renderedProducts, setRenderedProducts] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState({});
  const [progress, setProgress] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [totalProductsValue, setTotalProductsValue] = useState(0);
  const [isShowPreviousButton, setIsShowPreviousButton] = useState(false);
  const isTouchDevice = Math.min(width) < 1025 && Math.min(width) > 786;
  const [isSearching, setIsSearching] = useState(false);
  const [sortValue, setSortValue] = useState(null);
  const isMobile = Math.min(width) < 786;
  const { data: { products = [], totalProducts = 0 } = {}, isLoading, refetch, isFetching } = useQuery(
    ['searchResults', query, currentPage, sortValue],
    () => query && fetchSearchResults(query, MAX_PRODUCT_PER_PAGE, MAX_PRODUCT_PER_PAGE * (currentPage - 1), sortValue),
    { onSettled: () => setIsSearching(false) }
  );
  const resetPage = () => {
    setIsSearching(true);
    setTotalProductsValue(0);
    setLoadedProducts([]);
    setCurrentPage(params.get('page') || 1);
    setRenderedProducts([]);
    setLoadedProducts({});
    refetch();
  };

  const _handleSortChange = event => {
    handleSortChange(event);
    setSortValue(event);
  };

  useEffect(() => {
    resetPage();
  }, [query]);

  useEffect(() => {
    if (products && products.length > 0) {
      setLoadedProducts(prevLoadedProducts => {
        const updatedProducts = { ...prevLoadedProducts, [currentPage]: products };
        return updatedProducts;
      });
    } else if (currentPage === 1) {
      setLoadedProducts([]);
      setRenderedProducts([]);
    }
  }, [totalProducts, products, currentPage, isSearching]);

  useEffect(() => {
    if (!isLoading && !isFetching && totalProducts !== totalProductsValue) {
      setTotalProductsValue(totalProducts);
    }
  }, [totalProducts, totalProductsValue, isLoading, isFetching]);

  useEffect(() => {
    if (Object.keys(loadedProducts).length > 0) {
      const updatedRenderedProducts = Object.values(loadedProducts).flat();
      setRenderedProducts(updatedRenderedProducts);

      const loadedPages = Object.keys(loadedProducts)
        .map(Number)
        .sort((a, b) => a - b);
      const isPreviousDataNotLoaded = loadedPages[0] - 1 !== 0;
      setIsShowPreviousButton(isPreviousDataNotLoaded);
    }
  }, [loadedProducts]);

  const onOpen = product => {
    setOpenModal(true);
    setSelectedProduct(prepareDataForCartPopup(product));
  };

  const onClose = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    setProgress((renderedProducts?.length / totalProducts) * 100);
  }, [renderedProducts, totalProducts]);

  const showMoreHandler = () => {
    const page = HandleNextPage(currentPage);
    if (renderedProducts?.length <= totalProducts) setCurrentPage(page);
  };

  const showPreviousHandler = () => {
    const page = HandlePreviousPage(currentPage);
    if (page === 1) setIsShowPreviousButton(false);
    setCurrentPage(page);
  };

  if (location.pathname !== '/search') return null;

  const searchSeoTags = query && {
    title: `${CapitalizeWords(query)} - Search | SwagUp`,
    description: 'Shopping Experience'
  };

  return (
    <>
      <Helmet tags={searchSeoTags} />
      <Modal title="Product added to cart" open={openModal} onClose={onClose}>
        {selectedProduct && (
          <CartPopup onClose={onClose} details={selectedProduct} isTouchDevice={isTouchDevice} isMobile={isMobile} />
        )}
      </Modal>
      <Grid container style={{ padding: '0px 40px' }} justifyContent="align" alignItems="center">
        {isSearching || isLoading || renderedProducts.length > 0 ? (
          <>
            <SwagupTypography variant="body2RegularInter" style={{ marginBottom: '10px' }}>
              Your search
            </SwagupTypography>
            <Grid container justifyContent="flex-start" alignItems="baseline" style={{ gap: '10px' }}>
              <SwagupTypography variant="h4SemiBoldInter" style={{ textTransform: 'capitalize' }}>
                {`"${query}"`}
              </SwagupTypography>
              <SwagupTypography
                variant="body2RegularInter"
                style={{ color: shoppingExperienceColors.info }}
              >{`(${totalProductsValue} results)`}</SwagupTypography>
              <Grid xs container justifyContent="flex-end">
                <FilterButton
                  isMobile={isMobile}
                  handleSortChange={_handleSortChange}
                  sortItem={sortItem}
                  isHideFilters
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} style={{ paddingTop: '16px' }}>
              {isShowPreviousButton && (
                <Grid style={{ marginBottom: 20, justifyContent: 'center', width: '100%', display: 'inline-flex' }}>
                  {' '}
                  <PaginationButton
                    title="Show previous"
                    showMore={false}
                    showPreviousHandler={showPreviousHandler}
                  />{' '}
                </Grid>
              )}
              {(!(isSearching || isLoading) ? renderedProducts : new Array(8).fill(''))?.map((product, ind) => (
                <Grid
                  item
                  xs={6}
                  md={3}
                  sm={6}
                  lg={3}
                  key={ind}
                  style={{
                    display: 'inline-grid',
                    justifyContent: 'center'
                  }}
                >
                  <Product
                    details={product}
                    isTouchDevice={isTouchDevice}
                    isMobile={isMobile}
                    onOpen={() => onOpen(product)}
                  />
                </Grid>
              ))}
            </Grid>
            <Pagination
              progress={progress}
              renderedProducts={renderedProducts?.length}
              totalProducts={totalProductsValue}
              showMoreHandler={showMoreHandler}
              isLoading={isLoading}
            />
          </>
        ) : (
          <Grid container style={{ padding: '60px 0px' }} justifyContent="center" alignItems="center">
            <SwagupTypography variant="h4SemiBoldInter" style={{ marginTop: '10px' }}>
              We couldn’t find anything for <span style={{ textTransform: 'capitalize' }}>"{query}"</span>
            </SwagupTypography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SearchResults;
