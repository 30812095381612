/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { Grid, Card, CardContent, makeStyles } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { Helmet } from '../../components/shared';
import seoTags from '../../apis/seoTags';
import swagPackImage from '../assets/images/onboarding/onboarding-swag-pack.png';
import swagBulkImage from '../assets/images/onboarding/onboarding-swag-bulk.png';
import swagExpertImage from '../assets/images/onboarding/onboarding-expert-help.png';
import { NavigateTo } from '../shared/ui/common';
import TypeFormExpertModal from '../shared/ui/typeFormExpertModal';
import { CommonButton } from '../shared/ui/commonButtons';
import { shoppingExperienceColors } from '../shared/constants';
import { RightArrowIcon } from '../shared/icons';
import useResponsive from '../hooks/useResponsive';
import { useCartContext } from '../context/cart.context';

const useStyles = makeStyles(() => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textAlign: 'center',
    height: '435px'
  },
  image: {
    height: '210px',
    width: '250px',
    objectFit: 'contain'
  },
  imageTablet: {
    height: '150px',
    width: '150px',
    objectFit: 'contain'
  },
  subText: {
    margin: '20px 0px',
    paddingTop: '16px'
  },
  cardWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'start'
  }
}));

const CustomCard = ({
  imageSrc,
  title,
  buttonText,
  buttonLink,
  showSubText,
  subText,
  subTextLink,
  onButtonClick,
  uniqueId
}) => {
  const classes = useStyles();
  const { isTablet } = useResponsive();

  return (
    <Card elevation={0} className={classes.cardContainer} style={{ padding: isTablet ? '20px' : '20px 40px' }}>
      <img src={imageSrc} alt={title} className={isTablet ? classes.imageTablet : classes.image} />
      <CardContent style={{ padding: 0 }}>
        <SwagupTypography variant={isTablet ? 'body3SemiBoldInter' : 'h6SemiBoldInter'} style={{ margin: '20px 0px' }}>
          {title}
        </SwagupTypography>
        <Grid container style={{ flexDirection: 'column' }}>
          <CommonButton
            id={`button-${uniqueId}-onboarding`}
            w="auto"
            mt="0px"
            mr="0px"
            isActive
            onClick={onButtonClick || (() => buttonLink())}
            hasIcon
            icon={<RightArrowIcon fontSize={20} color={shoppingExperienceColors.white} />}
          >
            <SwagupTypography
              variant={isTablet ? 'buttonSmallInter' : 'buttonMediumInter'}
              style={{ color: shoppingExperienceColors.white }}
            >
              {buttonText}
            </SwagupTypography>
          </CommonButton>
          {showSubText && (
            <div id="explorePresetPacks" className={classes.subText} onClick={subTextLink}>
              <SwagupTypography
                variant={isTablet ? 'buttonSmallInter' : 'buttonMediumInter'}
                style={{ color: shoppingExperienceColors.primary, cursor: 'pointer' }}
              >
                {subText}
              </SwagupTypography>
            </div>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};

const ProjectOnboarding = () => {
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const { isTablet } = useResponsive();

  const { setCartTypeToPack, setCartTypeToBulk } = useCartContext();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const MobileOrLaptopOrDesktopLayout = () => {
    return (
      <Grid container className={classes.cardWrapper}>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomCard
            uniqueId="customSwagPack"
            imageSrc={swagPackImage}
            title="Create a custom swag pack"
            buttonText="Build my own pack"
            buttonLink={() => {
              setCartTypeToPack();
              NavigateTo(history, '/catalog/collections');
            }}
            showSubText
            subText="Explore preset packs"
            subTextLink={() => {
              setCartTypeToPack();
              NavigateTo(history, '/preset-packs/');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomCard
            uniqueId="bulkItems"
            imageSrc={swagBulkImage}
            title="Individual products"
            buttonText="Select bulk items"
            buttonLink={() => {
              setCartTypeToBulk();
              NavigateTo(history, '/catalog/collections');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
          <CustomCard
            uniqueId="expertHelp"
            imageSrc={swagExpertImage}
            title="Want an expert to help?"
            buttonText="Get expert help"
            onButtonClick={handleOpenModal}
          />
        </Grid>
      </Grid>
    );
  };

  const TabletLayout = () => {
    return (
      <Grid
        container
        className={classes.cardWrapper}
        spacing={2}
        style={{ flexWrap: 'nowrap', justifyContent: 'space-between' }}
      >
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ flex: '1 1 auto', width: '370px' }}>
          <CustomCard
            uniqueId="customSwagPack"
            imageSrc={swagPackImage}
            title="Create a custom swag pack"
            buttonText="Build my own pack"
            buttonLink={() => {
              NavigateTo(history, '/catalog/collections');
            }}
            showSubText
            subText="Explore preset packs"
            subTextLink={() => {
              NavigateTo(history, '/preset-packs/');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ flex: '1 1 auto', width: '370px' }}>
          <CustomCard
            uniqueId="bulkItems"
            imageSrc={swagBulkImage}
            title="Individual products"
            buttonText="Select bulk items"
            buttonLink={() => {
              NavigateTo(history, '/catalog/collections');
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} style={{ flex: '1 1 auto', width: '370px' }}>
          <CustomCard
            uniqueId="expertHelp"
            imageSrc={swagExpertImage}
            title="Want an expert to help?"
            buttonText="Get expert help"
            onButtonClick={handleOpenModal}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Helmet tags={seoTags.projectOnboarding} />
      <Grid container>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          style={{ flexDirection: 'column', gap: '20px', marginBottom: '20px' }}
        >
          <Grid item style={{ textAlign: 'center' }}>
            <SwagupTypography variant="h2SemiBoldInter">Let’s start your swag project</SwagupTypography>
          </Grid>
          <Grid item>
            <SwagupTypography variant="body2RegularInter">Select one of the options below:</SwagupTypography>
          </Grid>
        </Grid>
        {isTablet ? <TabletLayout /> : <MobileOrLaptopOrDesktopLayout />}
      </Grid>
      <TypeFormExpertModal open={openModal} onClose={handleCloseModal} />
    </>
  );
};

export default ProjectOnboarding;
