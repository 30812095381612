import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button } from '@swagup-com/components';
import { Grid, Hidden } from '@material-ui/core';
import { RightArrowIcon } from '../../shared/icons';
import { bannerStyles } from './banner.styles';
import { UrlStringFormatter } from '../../shared/ui/common';

const useStyles = makeStyles(bannerStyles);

const Banner = ({
  image,
  title,
  mobileTitle,
  subtitle,
  buttonLabel,
  buttonLink,
  isMobile,
  FreeInventoryStorageFee
}) => {
  const classes = useStyles();
  const history = useHistory();
  const highlightWord = (title, word) => {
    const parts = title?.split(word);
    return (
      <>
        {parts[0]}
        <span className={classes.highlighted}>{word}</span>
        {parts[1]}
      </>
    );
  };
  return (
    <Grid container className={classes.bannerContainer}>
      <Grid item className={classes.banner}>
        <Grid className={classes.bannerContent}>
          <Grid item>
            <img src={image} alt={title} className={classes.image} width={70} height={80} />
          </Grid>
          <Grid alignItems="center">
            <Typography variant="h5SemiBoldInter">{isMobile ? mobileTitle : title}</Typography>
            <Hidden smDown>{subtitle}</Hidden>
          </Grid>
        </Grid>
        {FreeInventoryStorageFee ? null : (
          <Grid>
            <Button
              variant="contained"
              size="small"
              endIcon={<RightArrowIcon fontSize="24px" />}
              onClick={() => window.open(buttonLink, '_blank')}
              className={classes.upgradeButtonStyle}
            >
              <Typography variant="buttonSmallInter" style={{ color: '#131415' }}>
                {buttonLabel}
              </Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default Banner;
