// from platform_api

const paymentMethods = {
  creditCard: 'Credit Card',
  ach: 'ACH',
  wireTransfer: 'Wire Transfer',
  mailCheck: 'Mail Check',
  accountCredits: 'Account Credits'
};

const pendingMockup = 'Pending Mockup';
const inProgress = 'In Progress';
const designReady = 'Design Ready';
const changesRequested = 'Changes Requested';
const productionRequest = 'Production Request';
const productionReady = 'Production Ready';
const approved = 'Approved';

const productStatus = {
  pendingMockup,
  inProgress,
  designReady,
  changesRequested,
  productionRequest,
  productionReady,
  approved,
  inactive: 'Inactive',

  // new status
  mockupDesign: 'Mockup Design',
  mockupReview: 'Mockup Review',
  proofDesign: 'Proof Design',
  proofReview: 'Proof Review',
  readyToOrder: 'Ready To Order'
};

const paid = 'Paid';
const pendingPayment = 'Pending Payment';
const inProduction = 'In Production';
const complete = 'Complete';

const accountOrderProductStatus = {
  pendingMockup,
  approved,
  paid,
  pendingPayment,
  inProduction,
  complete
};

const inDesign = 'In Design';
const canceled = 'Canceled';

const shipmentStatus = {
  pendingProduction: 'Pending Production',
  scheduled: 'Scheduled',
  inReview: 'In Review',
  onItsWay: 'On Its Way',
  delivered: 'Delivered',
  failure: 'Failure',
  cancelled: 'Cancelled',
  canceled: 'Canceled',
  invalidAddress: 'Invalid Address',
  returnToSender: 'Return To Sender',
  success: 'Success',
  incomplete: 'Incomplete',
  outOfStock: 'Out Of Stock',
  insufficientCredit: 'Insufficient Credit',
  paymentProcessed: 'Payment Processed'
};

const productionTime = {
  standard: 'Standard',
  rush: 'Rush'
};
/* won't use this for now, only opportunityStatus
const opportunityStage = {
  new: 'New',
  designRequested: 'Design Requested',
  designReady,
  proposalSent: 'Proposal Sent',
  changesRequested,
  negotiation: 'Negotiation',
  productionRequest,
  productionApproved: 'Production Approved',
  closedWon: 'Closed Won',
  closedLost: 'Closed Lost'
};
*/

const finalizingProofsOld = 'Finalizing Proofs';
const proofsApprovedOld = 'Proofs Approved';

const finalizingProofs = 'Production Requests';
const proofsApproved = 'Production Approved';

const opportunityStatus = {
  inDesign,
  designReady,
  changesRequested,
  finalizingProofs,
  proofsApproved,
  approved,
  finalizingProofsOld,
  proofsApprovedOld
};

const isPendingPayment = 2;

const invoiceStatus = {
  pendingPayment,
  paid,
  canceled,
  refunded: 'Refunded',
  processingError: 'Processing Error',
  processingPayment: 'Processing Payment',
  creditExchange: 'Credit/Exchange'
};

const quoteType = {
  warehouse: 'Warehouse Order',
  oneAddress: 'One Address',
  multipleAddresses: 'Multiple Addresses'
};

const opportunityProductStatus = {
  pendingMockup,
  pendingConfirmation: 'Pending Confirmation',
  approved
};

// other constants ¿?

export {
  paymentMethods,
  productStatus,
  accountOrderProductStatus,
  shipmentStatus,
  productionTime,
  //  opportunityStage,
  opportunityStatus,
  isPendingPayment,
  invoiceStatus,
  quoteType,
  opportunityProductStatus
};
