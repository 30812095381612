import {
  DashboardOutlined as DashboardIcon,
  StorefrontOutlined as StorefrontIcon,
  ShoppingBasketOutlined as ShoppingBasketIcon,
  LocalShippingOutlined as LocalShippingIcon,
  PersonOutline as PersonIcon,
  RedeemOutlined as RedeemIcon,
  ShoppingCartOutlined as ShoppingCartIcon,
  CreditCardOutlined as CreditCardIcon,
  SettingsOutlined as SettingsIcon,
  HelpOutline as HelpOutlineIcon
} from '@material-ui/icons';
import EmailImage from '../shoppingExperience/assets/images/confirmation/email-activation.png';
import PenImage from '../shoppingExperience/assets/images/confirmation/edit-mockups.png';
import DocImage from '../shoppingExperience/assets/images/confirmation/mockups.png';
import LikeImage from '../shoppingExperience/assets/images/confirmation/place-order.png';

import { IntegrationMenuIcon, InventoryMenuIcon } from '../components/icons';

export const CATALOG_CART_WIDTH = 300;

export const CATALOG_PRODUCT_TYPE = {
  PRODUCT: 'Product',
  PACK: 'Pack'
};

export const NAVIGATION_PATHS = [
  { Icon: DashboardIcon, isActive: false, label: 'Dashboard', path: '/', pathsToHighlight: ['/'] },
  {
    Icon: StorefrontIcon,
    isActive: false,
    label: 'Products',
    path: '/products',
    pathsToHighlight: ['/products', '/product-details', '/proof-details']
  },
  {
    Icon: ShoppingBasketIcon,
    isActive: false,
    label: 'Orders',
    path: '/orders-requested',
    pathsToHighlight: ['/orders-requested', '/orders-completed']
  },
  {
    Icon: <InventoryMenuIcon />,
    isActive: false,
    label: 'Inventory',
    path: '/inventory?inventory=available',
    pathsToHighlight: ['/inventory'],
    customIcon: true
  },
  {
    Icon: LocalShippingIcon,
    isActive: false,
    label: 'Shipments',
    path: '/shipments',
    pathsToHighlight: ['/shipments']
  },
  {
    Icon: PersonIcon,
    isActive: false,
    label: 'Contacts',
    path: '/contacts',
    pathsToHighlight: ['/contacts', '/add-employee', '/contact-details']
  },
  {
    Icon: RedeemIcon,
    isActive: false,
    label: 'Redeem',
    path: '/redeem-pages',
    pathsToHighlight: ['/redeem-pages', '/redeem-history']
  },
  {
    Icon: ShoppingCartIcon,
    isActive: false,
    label: 'Shops',
    path: '/shops',
    pathsToHighlight: ['/shops', '/shop-details']
  },
  {
    Icon: <IntegrationMenuIcon />,
    isActive: false,
    label: 'Integrations',
    path: '/integrations',
    pathsToHighlight: ['/integrations', '/integration-details'],
    customIcon: true
  },
  {
    Icon: CreditCardIcon,
    isActive: false,
    label: 'Billing',
    path: '/billing/accounts',
    pathsToHighlight: [
      '/billing/accounts',
      '/billing/invoices',
      '/billing/credits',
      '/billing/transactions',
      '/billing/add-credits'
    ]
  },
  {
    Icon: SettingsIcon,
    isActive: false,
    label: 'Settings',
    path: '/settings/profile',
    pathsToHighlight: ['/settings/profile', '/settings/company', '/settings/users', '/settings/your-membership']
  },
  { Icon: HelpOutlineIcon, isActive: false, label: 'Help Center', pathsToHighlight: [] }
];

export const DEFAULT_COLLECTION_TO_BE_SELECTED = 'Staff Picks';

export const SEARCH_DEBOUNCE_MS = 500;

export const COLOR_CONSTANT = {
  NEUTRAL_GREY: '#F5F5F6',
  DARK_GREY: '#989EA4',
  TEXT_GREY: '#131415',
  TEXT_WHITE: '#FFF',
  LIGHT_BORDER_GREY: '#D6D8DB',
  SELECTED_BLUE: '#125CFF'
};

export const defaultSortOptions = {
  '-created_at': 'Most Recent',
  created_at: 'Less Recent',
  name: 'A - Z',
  '-name': 'Z - A'
};

export const hiddenNavbarRoutes = [
  '/dashboard/',
  '/bulk/catalog',
  '/custom-swag-packs/catalog',
  '/send-swag/select-products',
  '/send-swag/select-recipients',
  '/send-swag/payment',
  '/send-swag/payment/review-shipment',
  '/send-swag/payment/payment-method',
  '/send-swag-review',
  '/onboarding/welcome',
  '/onboarding/name',
  '/onboarding/email',
  '/onboarding/company',
  '/onboarding/position',
  '/onboarding/location',
  '/onboarding/address',
  '/onboarding/credit-card',
  '/onboarding/phone',
  '/onboarding/size',
  '/onboarding/goal',
  '/onboarding/quantity',
  '/onboarding/how-do-you-learn-about-us',
  '/onboarding/thank-you',
  '/onboarding/mockups/upload-logo',
  '/onboarding/mockups/thanks',
  '/redeem-pages-create',
  '/shop-create',
  '/membership/your-membership',
  '/shop-customize',
  '/redeem-details',
  '/ship-swag-overview',
  '/import-contacts',
  '/product-onboarding',
  '/okta-signup',
  '/okta-callback',
  '/okta/logout/callback',
  // * Hides Left NavBar on the below New Shopping Experience paths
  '/catalog',
  '/collections',
  '/cart',
  '/search',
  '/product-onboarding',
  '/start-project',
  '/project-submission',
  '/project-submission-confirmation',
  '/preset-swag-packs/catalog'
];

export const hiddenTopBarHeaderRoutes = [
  '/catalog',
  '/collections',
  '/cart',
  '/search',
  '/start-project',
  '/project-submission',
  '/project-submission-confirmation',
  '/okta-callback'
];

export const PRODUCT_STATUS = ['Mockup design', 'Mockup review', 'Proof design', 'Proof review', 'Ready to order'];

export const PRODUCT_STATUS_OPTIONS = {
  'Mockup Design': 'Mockup design',
  'Mockup Review': 'Mockup review',
  'Proof Design': 'Proof design',
  'Proof Review': 'Proof review',
  'Ready To Order': 'Ready to order'
};

export const PRODUCT_DUE_DATE_STATUSES = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

export const CONFIRMATION_STEPS = [
  {
    imagePath: EmailImage,
    title: `Activate your account`,
    description: `We created a free account for you! Check your  email and follow the steps to activate your account & gain access to your personalized swag dashboard.`
  },
  {
    imagePath: DocImage,
    title: `Review your mockups`,
    description: `Your mockups will be ready within 1 business day! Be on the lookout for an email notification.`
  },
  {
    imagePath: PenImage,
    title: 'Edit and approve your products',
    description: `Let's make this project perfect! Request design edits and approve when ready.`
  },
  {
    imagePath: LikeImage,
    title: 'Place your order',
    description: `Once everything looks perfect, pay and place your order! We'll then begin production which typically takes 3-4 weeks.`
  }
];
