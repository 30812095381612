import apiPaths from '../../helpers/apiPaths';
import DashBoard from '../../apis/DashBoard';

const subscribeToNewsletter = async (email, first_name) => {
  const data = {
    email,
    first_name
  };
  try {
    const response = await DashBoard.post(apiPaths.subscribers, data);
    return {
      status: response.status,
      message: response.data?.message || null
    };
  } catch (error) {
    throw {
      status: error.data?.status,
      title: error.data?.title,
      detail: error.data?.detail
    };
  }
};

export default subscribeToNewsletter;
