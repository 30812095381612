import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import MembershipHand from '../../assets/images/products/membership_hand.png';
import useResponsive from '../../hooks/useResponsive';
import { getBreadCrumbs } from '../../shared/helper';
import Banner from '../Banner/banner';
import Breadcrumb from '../Breadcrumb/breadcrumb';
import TitleHeader from '../TitleHeader/titleHeader';
import styles from './collections.styles';
import CollectionsCard from './collectionsCard';
import useMembershipCheck from '../../../hooks/useMembershipCheck';
import BannerSubtitleText from '../Banner/bannerSubtitleText';
import { useFilterContext } from '../../contexts/filter.context';

const useStyles = makeStyles(styles);

const CollectionsLisiting = ({
  selectedCollectionId = '',
  pageTitle = '',
  currentPage,
  setCurrentPage,
  groupedFilter,
  setGroupedFilter,
  handleSortChange,
  sortItem,
  setLoadedProducts,
  loadedProducts
}) => {
  const classes = useStyles();
  const { isMobile } = useResponsive();
  const PATHS = getBreadCrumbs();
  const [totalProducts, setTotalProducts] = useState(0);
  const membershipCheck = useMembershipCheck();
  const PlatinumMembershipTier = membershipCheck?.isPlatinum;
  const [updatedPaths, setUpdatedPaths] = useState([]);
  const { appliedFilters } = useFilterContext();

  useEffect(() => {
    if (appliedFilters.length > 0) {
      const _category = appliedFilters?.find(i => i.type === 'Category');
      PATHS.push({
        collectionRoute: true,
        name: _category?.label,
        to: `/catalog/${String(_category?.label)
          .replace(/[\s&]+/g, '-')
          .toLowerCase()}`
      });
      setUpdatedPaths(PATHS);
    } else {
      const _updatedPath = PATHS.filter(item => !('collectionRoute' in item));
      setUpdatedPaths(_updatedPath);
    }
  }, [appliedFilters]);

  return (
    <Grid container className={classes.collectionsLayout}>
      <Breadcrumb routes={updatedPaths} />
      <TitleHeader
        title={pageTitle}
        showFilter
        totalProducts={totalProducts}
        groupedFilter={groupedFilter}
        setGroupedFilter={setGroupedFilter}
        selectedCollectionId={selectedCollectionId}
        isCollectionPage
        handleSortChange={handleSortChange}
        sortItem={sortItem}
      />
      <CollectionsCard
        selectedCollectionId={selectedCollectionId}
        setTotalProducts={setTotalProducts}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        groupedFilter={groupedFilter}
        sortItem={sortItem}
        loadedProducts={loadedProducts}
        setLoadedProducts={setLoadedProducts}
      />
      {!PlatinumMembershipTier && (
        <Banner
          image={`${MembershipHand}`}
          title="Platinum Members Get 5% Off All Products"
          mobileTitle="Platinum Members Get 5% Off All Products"
          subtitle={<BannerSubtitleText />}
          buttonLabel="Upgrade to Platinum"
          buttonLink="https://www.swagup.com/membership"
          highlightedWord="Platinum"
          isMobile={isMobile}
        />
      )}
    </Grid>
  );
};

export default CollectionsLisiting;
