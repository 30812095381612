import React from 'react';
import { useRouter } from 'next/router';
import NextLink from 'next/link';
import { Link as ReactRouterLink } from 'react-router-dom';
import MuiLink from '@material-ui/core/Link';

const Link = ({ children, to, absolute = false, as, ...props }, ref) => {
  const isSSR = useRouter();

  if ((!to?.pathname && to?.startsWith('http')) || (!isSSR && absolute))
    return (
      <MuiLink href={to} ref={ref} {...props}>
        {children}
      </MuiLink>
    );

  if (isSSR) {
    return absolute ? (
      <NextLink href={to} as={as}>
        <a ref={ref} {...props}>
          {children}
        </a>
      </NextLink>
    ) : (
      <a href={`/dashboard${to}`} ref={ref} {...props}>
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={to} {...props}>
      {children}
    </ReactRouterLink>
  );
};

export default React.forwardRef(Link);
