import { round } from 'lodash';
import { useLocation } from 'react-router-dom';

export const getPlatinumMembershipDetails = memberships => {
  if (!Array.isArray(memberships)) {
    console.error('Input is not a valid array.');
    return null;
  }
  const platinumProgram = memberships.find(program => program?.name === 'Platinum');
  if (!platinumProgram) {
    console.log('No Platinum membership found.');
    return null;
  }
  return {
    productDiscount: platinumProgram?.product_discount,
    rewardPointsRate: platinumProgram?.reward_points_rate,
    storageDiscount: platinumProgram?.storage_discount === 100 ? 'Free storage' : platinumProgram?.storage_discount,
    shopTransactionFee: platinumProgram?.shop_transaction_fee,
    shippingDiscount: platinumProgram?.shipping_discount
  };
};

export const getPlatinumProductDiscountPrice = (price, productDiscount) => {
  const discountValue = (price * productDiscount) / 100;
  return round(discountValue, 2);
};

export const getLastPathSegment = url => {
  const segments = url?.split('/');
  const lastSegment = segments?.length > 0 ? decodeURIComponent(segments[segments?.length - 1]) : '';

  return lastSegment;
};
